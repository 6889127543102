import { FaSadTear } from "react-icons/fa";
import {Link} from "react-router-dom";

export default function NotFoundPage() {
    return (
        <div className={'h-[500px] flex justify-center gap-2 items-center'}>
            <FaSadTear />
            <p>Page Not Found.</p>
            <Link to={''} className={'text-primary'}>Return Home.</Link>
        </div>
    )
}