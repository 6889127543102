import { useState } from "react";
import ProfileTab from "./ProfileTab";
import MyContributionTab from "./ContributionTabs";
import SpecialContributions from "./Special";

export default function ProfileTabs() {
    const [active, setActive] = useState(0);
    const main_tabs = ['Profile', 'Contributions', 'Special Contributions'];

    const isLocal = localStorage.getItem('userDetails');
    console.log(isLocal === null );

    return (
        <div className={'w-full flex flex-col'}>
            <div className="flex flex-row bg-slate-200 w-full p-1 rounded">
                {main_tabs.map((item, index) => {
                    if (index === 1 && isLocal !== null) return null;
                    if (index === 2 && isLocal === null) return null;
                    return (
                        <div onClick={() => setActive(index)} key={index} className={`p-2 px-5 ${active === index ? 'bg-primary  text-white' : 'bg-slate-200 text-black'} cursor-pointer rounded`}>
                            {item}
                        </div>
                    );
                })}
            </div>
            <div className="mt-3">
                {active === 0 && <ProfileTab />}
                {active === 1 && (isLocal === null ? <MyContributionTab /> : null)}
                {active === 2 && (isLocal !== null ? <SpecialContributions /> : null)}
            </div>
        </div>
    );
}
