import { useTranslation } from "react-i18next";
import image from "../../assets/images/demo2.jpeg";
import ContributionTabs from "./ContributionTabs";

export default function ContributionPage() {
    const { t } = useTranslation();
    return (
        <div className={'min-h-screen flex flex-row'}>
            <div className="hidden md:block md:w-1/2" style={{
                background: `url('${image}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                backgroundAttachment: 'fixed'
            }}></div>

            <div className="p-5 w-full md:w-1/2">
                <h1 className={'text-2xl font-bold mb-3'}>{t("Contribution")}</h1>

                <ContributionTabs />
            </div>
            
        </div>
    );
}