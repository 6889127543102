import {Outlet, useLocation} from "react-router-dom";
import MainHeader from "../components/mainHeader";
import { useEffect } from "react";

export default function HomeContainer() {
    return (
        <div>
            <ScrollToTop />
            <MainHeader />
            <Outlet />
        </div>
    )
}

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
