import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {appLogin, appLogOut} from "../store/reducers/auth";
import {useNavigate} from "react-router-dom";

export const useIsAuth = () => {
    const token = localStorage.getItem('userToken');
    const dispatch = useAppDispatch();
    useEffect(() => {
        if(!token) {
            dispatch(appLogOut());
        } else {
            dispatch(appLogin())
        }
    }, [ token ]);

    return null;
}

export const useProtect = () => {
    const { isLoggedIn } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if(!isLoggedIn) {
            navigate('/')
        }
    }, [isLoggedIn]);

    return null;
}