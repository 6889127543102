import { motion } from 'framer-motion';
import {ReactNode} from "react";

export default function AnimatedContainer({ children } : { children: ReactNode }) {
    return (
        <motion.div
            initial={{ scale: 1, y: '-10px', opacity: 1 }}
            animate={{ scale: 1, y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            {children}
        </motion.div>
    )
}