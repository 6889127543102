import React, { useState } from "react";
import DataTable from "../../components/datatable/datatable";
import { useGetMemberList } from "../../api/auth/main";
import { approveMembership } from "../../api/auth/main";



const MemberList: React.FC = () => {

 
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); 
  const { loading, members, error, refetch }: any = useGetMemberList(
    pageSize,
    currentPage,
    search,
    status
  );
  
  const handleStatusChange = (e:any) => {
    setStatus(e.target.value);
  };

  const columns: string[] = [
    "name",
    "gender",
    "identification_type",
    "nid",
    "dateOfBirth",
    "email",
    "phoneNumber",
    "residence_country",
    "province",
    "district",
    "sector",
    "cell",
    "village",
    "status",
    "created_at",
  ];
  const labels: { [key: string]: string } = {
    name: "Name",
    gender: "Gender",
    identification_type: "Identification Type",
    nid: "NID/Passport ID",
    dateOfBirth: "Date of Birth",
    email: "Email",
    phoneNumber: "Phone Number",
    residence_country: "Residence Country",
    province: "Province",
    district: "District",
    sector: "Sector",
    cell: "Cell",
    village: "Village",
    status: "Status",
    created_at: "Created At",
    updated_at: "Joined",
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleApprove = async (row: any) => {
    try {
        const payload:any = {
            id: row.id,
            status: "approved"
        }
      await approveMembership(payload);
      await refetch();
    } catch (error) {
      console.error("Error approving member:", error);
    }
  };

  const handleReject = async (row: any) => {
    try {
        const payload:any = {
            id: row.id,
            status: "approved"
        }
      await approveMembership(payload);
      await refetch();
    } catch (error) {
      console.error("Error rejecting member:", error);
    }
  };

  const actions = [
    {
      label: "Approve",
      onClick: handleApprove,
    },
    {
      label: "Reject",
      onClick: handleReject,
    },
  ];

 

  if (error) {
    return <div>Error: {error}</div>;
  }
  const onSearch = (query: string) => {
    setSearch(query);
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold text-center mt-8 mb-4">Member List</h1>
      <DataTable
        data={members?.list ?? []}
        columns={columns}
        onSearch={onSearch}
        labels={labels}
        totalItems={members?.total ?? 0}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        actions={actions}
        Filter={
          <div>
      <label>
        Filter by Status:
        <select value={status} onChange={handleStatusChange}>
          <option value="">All</option>
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </select>
      </label>
    </div>
        }
      />
    </div>
  );
};

export default MemberList;



