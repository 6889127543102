import { FaSadTear } from "react-icons/fa";
import {Link} from "react-router-dom";
import {TermsComponent} from "../../components/modal/Terms_and_condition";

export default function TermsAndConditions() {
    return (
        <div className={'flex flex-col justify-center sm:px-20 gap-2 items-center'}>
            <TermsComponent />
        </div>
    )
}