import image from "../../assets/images/demo2.jpeg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useHandleSuccessMutation} from "../../api/contributions/main";

export default function FailPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [send_success, ] = useHandleSuccessMutation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const successId = queryParams.get('orderId');

        sessionStorage.clear();

        send_success({ orderId: successId, status: false  });

        if (!successId) {
            navigate('/');
        }
    }, [location.search]);

    return (
        <div className={'min-h-screen flex flex-row'}>
            <div className="hidden md:block md:w-1/2" style={{
                background: `url('${image}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                backgroundAttachment: 'fixed'
            }}></div>

            <div className="p-5 w-full md:w-1/2">
                <div className="bg-white p-6  md:mx-auto pt-32">
                    <div className="text-center">
                        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Failed!</h3>
                        <p className="text-gray-600 my-2">Looks like something went wrong, Try Again.</p>
                        <div className="py-10 text-center">
                            <Link to="/contribution"
                               className="px-12 bg-orange-500 rounded hover:bg-orange-600 text-white font-semibold py-3">
                                Retry
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}