import AuthContainer from "../contribution/auth";
import {useAppSelector} from "../../store/hooks";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export default function AuthPage() {
    const { isLoggedIn } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if(isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn]);
    return (
        <div className={'py-5 w-full flex justify-center m-2  items-center'}>
            <div className="w-[50%]">
                <AuthContainer />
            </div>
        </div>
    )
}