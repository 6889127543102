import {countries} from "../assets/countries";
import Select from 'react-select'
import {ValidationRules} from "./types";

interface SelectProps {
    label: string,
    placeholder?: string,
    required?: boolean,
    error?: string;
    register: any;
    name: string;
    className?: string;
    setValue: any;
    validation?: ValidationRules;
    options: { label: string, value: string }[];
}

export default function AppSelect({ label,error,register,validation,setValue,name,options, placeholder, required }: SelectProps) {

    return (
        <div className="flex flex-col gap-1 relative">
            <label htmlFor="">{required ? '*' : ''} {label}</label>
            <Select {...register(name, validation)} onChange={(val : any) => setValue(name, val.value)} options={options} placeholder={placeholder} />

            {error &&  <p className={'text-xs text-red-500'}>{error}</p>}
        </div>
    )
}