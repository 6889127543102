import React, { useState, useEffect } from "react";
import { useGetContributions } from "../../../api/auth/main";
import DataTable from "../../../components/datatable/datatable";
import AnimatedContainer from "../../../container/animatedContainer";
import { useTranslation } from "react-i18next";


const SpecialContributions: React.FC = () => {
  const [search, setSearch] = useState(""); 
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); 
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  const [phoneNumber, setPhoneNumber] = useState(userDetails?.phoneNumber);
  const {  contributions, error, total }: any = useGetContributions(
    pageSize,
    currentPage,
    phoneNumber, 
  );
useEffect(() => {
    setPhoneNumber(userDetails?.phoneNumber);
}, [userDetails])

  console.log(phoneNumber)

  const columns: string[] = [
    "amount",
    "currency",
    "tinNumber",
    "companyName",
    "contributorName",
    "phone",
    "phoneNumber",
    "email",
    "residence_country",
    "contributionType",
    "transaction.phoneNumber",
    "transaction.telco",
    "transaction.payment_mode",
    "transaction.amount",
    "transaction.status",
    "createdAt",
  ];

  const labels: { [key: string]: string } = {
    amount: "Amount",
    currency: "Currency",
    tinNumber: "TIN Number",
    companyName: "Company Name",
    contributorName: "Contributor Name",
    phone: "Phone",
    phoneNumber: "Phone Number",
    email: "Email",
    residence_country: "Residence Country",
    contributionType: "Contribution Type",
    createdAt: "Date",
    "transaction.phoneNumber": "Transaction Phone Number",
    "transaction.telco": "Transaction Telco",
    "transaction.payment_mode": "Transaction Payment Mode",
    "transaction.amount": "Transaction Amount",
    "transaction.status": "Transaction Status",
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const { t } = useTranslation();

  const onSearch = (query: string) => {
    setSearch(query);
  };
  if (error) {
    return <div>Error: {error}</div>;
  }

  
  return (

    <AnimatedContainer>
        <div className="container mx-auto">
      <h1 className="text-lg font-bold text-center mt-8 mb-4">
            {t("My Special Contributions")}
      </h1>

      <DataTable
        data={contributions}
        columns={columns}
        onSearch={onSearch}
        labels={labels}
        totalItems={total}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      
      />
    </div>
    </AnimatedContainer>

  );
};

export default SpecialContributions;
