import { SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AnimatedContainer from "../../../container/animatedContainer";
import CountrySelect from "../../../components/CountrySelect";
import AppInput from "../../../components/AppInput";
import AppPhoneInput, { capitalize } from "../../../components/PhoneInput";
import mtn from "../../../assets/images/mtn.png";
import visa from "../../../assets/images/master.png";
import { removePlus, validations } from "../../../utils/validations";
import { FormDataNonMember } from "../../../components/types";
import {
  useGetDistricts,
  useGetProvinces,
  useMakeContributionNonMemberMutation,
  useRequestSessionMutation,
} from "../../../api/contributions/main";
import fireToast from "../../../components/toast";
import { SuccessModal } from "../../../components/SuccessModal";
import Terms_and_condition from "../../../components/modal/Terms_and_condition";
import { useTranslation } from "react-i18next";
import AppInput2 from "../../../components/AppInput2";
import { countries } from "../../../assets/countries";

function formatPhoneNumber(phoneNumber: string) {
  return phoneNumber.startsWith('0') ? phoneNumber.slice(1) : phoneNumber;
}
function formatMonth(monthString: string) {
  const month = parseInt(monthString);
  if (month < 1 || month > 12) {
    throw new Error("Invalid month number. Month must be between 1 and 12.");
  }
  return month < 10 ? `0${month}` : `${month}`;
}

function formatYear(yearString: string) {
  const year = parseInt(yearString);
  if (isNaN(year) || year < 0 || year > 9999) {
    throw new Error("Invalid year number.");
  }
  return String(year).slice(-2); // Extract the last two digits of the year
}

export default function NonMemberForm() {
  const [isInst, setIsInst] = useState(false);
  const [province, setProvince] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [district, setDistrict] = useState("");
  const [districtName, setDistrictName] = useState("");

  const [open, setOpen] = useState(false);
  const [currency, setCurrency] = useState<"rwf" | "usd" | "eur" | null>("rwf");
  const [payMethod, setPayMethod] = useState<"momo" | "card">("card");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
    setError,
  } = useForm<FormDataNonMember>({
    defaultValues: {
      phoneCode: "+250",
    },
  });

  const [contribute, { isLoading, isSuccess, data }] =
    useMakeContributionNonMemberMutation();

  const manage = (data: FormDataNonMember) => {
    if (!termsAccepted) {
      return fireToast({
        message: "Please accept the terms and conditions",
        type: "FAILED",
      });
    }
    if (currency === null) {
      fireToast({ message: "Select Currency", type: "FAILED" });
    } else {
      if (payMethod === "momo") {
        const isRwanda = data.residence === "rwanda";
        contribute({
          amount: data.amount,
          tinNumber: data.tin,
          contributorName: data.contributorName,
          phone : isRwanda ? `250${formatPhoneNumber(data.phoneNumber)}` : `${removePlus(data.phoneCode)}${data.phoneNumber}`,
          phoneNumber: `250${formatPhoneNumber(data.phoneNumber)}` ,
          contributionType: isInst ? 2 : 1,
          channel:'web',
          paymentMode: payMethod,
          email: data.email,
          companyName: data.companyName,
          residence_country: data.residence,
          locationId: districtName?.toLowerCase() ?? "",
        });
      } else {
        const isRwanda = data.residence === "rwanda";
        contribute({
          amount: data.amount,
          tinNumber: data.tin,
          contributorName: isInst ? data.contributorName : data.names,
          phone : isRwanda ? `250${formatPhoneNumber(data.phoneNumber)}` : `${removePlus(data.phoneCode)}${data.phoneNumber}`,
          contributionType: isInst ? 2 : 1,
          paymentMode: payMethod,
          channel:'web',
          email: data.email,
          companyName: data.companyName,
          residence_country: data.residence,
          locationId: districtName?.toLowerCase() ?? "",
          cardPayment:
            payMethod === "card"
              ? {
                  currency: currency.toUpperCase(),
                  card_number: data.cardNumber,
                  expiry_month: formatMonth(data?.month),
                  expiry_year: formatYear(data?.year),
                  security_code: data.cvv,
                }
              : null,
        });
      }
    }
  };

  const {
    loading: provincesLoading,
    provinces,
    refetch: refetchProvinces,
  } = useGetProvinces();
  const {
    loading: districtsLoading,
    districts,
    fetchDistricts,
  } = useGetDistricts();

  console.log(province);
  useEffect(() => {
    refetchProvinces();
  }, []);

  useEffect(() => {
    fetchDistricts(province);
  }, [province]);

  useEffect(() => {
    if (data?.status === "success") {
      setOpen(true);
      reset();
    }
  }, [data, reset]);

  const [requestSession, ops] = useRequestSessionMutation();

  useEffect(() => {
    if (ops.isError) {
      setSwitched(false);
    }
  }, [ops.isError]);

  const [switched, setSwitched] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (val: any) => {
    setSelectedCountry(val.value);
    setValue("residence", val.value);
  };

  const handleCurrencyChange = (event: any) => {
    const selectedCurrency = event.target.value;
    setCurrency(selectedCurrency);
    setPayMethod(selectedCurrency === "rwf" ? "momo" : "card");
  };

  const { t } = useTranslation();

  useEffect(() => {
    const country = countries.find(
      (country) =>
        country.name ===
        (watch("residence") ? capitalize(watch("residence")) : "Rwanda")
    );

    if (setValue && country) {
      setValue("phoneCode", country?.phoneCode ?? "");
    }
  }, [watch("residence")]);

  console.log(watch("phoneCode"));

  return (
    <AnimatedContainer>
      <form onSubmit={handleSubmit(manage)}>
        <div className="p-1 w-full bg-primary items-center flex justify-center text-white text-sm">
          {t("Basic Info")}
        </div>
        <div className="">
          <div className="flex flex-wrap py-2 gap-4">
            <label className="relative flex items-center cursor-pointer">
              <input
                checked={!isInst}
                onChange={() => setIsInst(false)}
                className="sr-only peer"
                name="futuristic-radio"
                type="radio"
              />
              <div className="w-4 h-4 bg-transparent border-2 border-primary rounded-full peer-checked:bg-primary peer-checked:border-primary transition duration-300 ease-in-out"></div>
              <span className="ml-2">{t("I am an individual")}</span>
            </label>

            <label className="relative flex items-center cursor-pointer">
              <input
                checked={isInst}
                onChange={() => setIsInst(true)}
                className="sr-only peer"
                name="futuristic-radio"
                type="radio"
              />
              <div className="w-4 h-4 bg-transparent border-2 border-primary rounded-full peer-checked:bg-primary peer-checked:border-primary transition duration-300 ease-in-out"></div>
              <span className="ml-2">{t("We are an institution")}</span>
            </label>
          </div>

          <div className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 gap-2">
            <CountrySelect
              name={"residence"}
              setValue={setValue}
              error={errors.residence?.message}
              register={register}
              label={t("Residence")}
              onChange={handleCountryChange}
              placeholder={t("Select your residence")}
              validation={validations.residence}
              required={true}
            />

            {watch("residence") === "rwanda" && (
              <>
                <div className=" flex flex-col ">
                  <label htmlFor="provinces">*{t("Province")}</label>
                  <select
                    id="provinces"
                    className={`py-[7px] rounded px-3 border  ${
                      errors.province ? "border-red-500" : "border-gray-400/70"
                    } focus:outline-none focus:ring-2 mt-1 focus:ring-primary`}
                    {...register("province", {
                      required: t("Please select a province"),
                    })}
                    onChange={(e: any) => {
                      setProvince(e.target.value);
                      setProvinceName(
                        provinces?.find(
                          (province) => province.id === e.target.value
                        )?.name ?? ""
                      );
                    }}
                  >
                    <option value="">{t("Select Province")}</option>
                    {provinces &&
                      provinces?.map((province) => (
                        <option key={province.id} value={province.id}>
                          {province.name}
                        </option>
                      ))}
                  </select>
                  {errors.province && (
                    <p className="text-red-600 text-xs">
                      {errors.province.message}
                    </p>
                  )}
                </div>
                <div className=" flex flex-col ">
                  <label htmlFor="District"> *{t("District")}</label>
                  <select
                    id="District"
                    className={`py-[7px] rounded px-3 border ${
                      errors.district ? "border-red-500" : "border-gray-400/70"
                    } focus:outline-none focus:ring-2 mt-1 focus:ring-primary`}
                    {...register("district", {
                      required: t("Please select a district"),
                    })}
                    onChange={(e: any) => {
                      setDistrict(e.target.value);
                      setDistrictName(
                        districts?.find(
                          (district) => district.id === e.target.value
                        )?.id ?? ""
                      );
                    }}
                  >
                    <option value="">{t("Select District")}</option>
                    {districts &&
                      districts?.map((district) => (
                        <option key={district.id} value={district.id}>
                          {district.name}
                        </option>
                      ))}
                  </select>
                  {errors.district && (
                    <p className="text-red-600 text-xs">
                      {errors.district.message}
                    </p>
                  )}
                </div>
              </>
            )}
            {!isInst && (
              <AppInput
                name={"names"}
                register={register}
                error={errors.names?.message}
                label={t("Names")}
                placeholder={t("Enter full names")}
                validation={validations.names}
                required={true}
              />
            )}
            <AppInput
              name={"email"}
              register={register}
              error={errors.email?.message}
              label={"Email"}
              type={"email"}
              placeholder={"Enter email"}
              validation={validations.email}
              required={true}
            />
            <AppPhoneInput
              setValue={setValue}
              name={"phoneNumber"}
              selectedCountry={watch("residence")}
              register={register}
              error={errors.phoneNumber?.message}
              label={t("Phone Number")}
              placeholder={t("Enter phone number")}
              required={false}
            />

            {isInst && (
              <>
                <AppInput
                  name={"tin"}
                  register={register}
                  label={t("TIN Number")}
                  error={errors.tin?.message}
                  validation={validations.globalRequired}
                  placeholder={t("Enter TIN")}
                />
                <AppInput
                  name={"companyName"}
                  register={register}
                  error={errors.companyName?.message}
                  validation={validations.globalRequired}
                  label={t("Company Name")}
                  placeholder={t("Enter Company name")}
                />
                <AppInput
                  name={"contributorName"}
                  register={register}
                  error={errors.contributorName?.message}
                  validation={validations.globalRequired}
                  label={t("Contributor Name")}
                  placeholder={t("Enter Contributor name")}
                />
              </>
            )}
          </div>
        </div>
        <div className="p-1 w-full bg-primary items-center flex justify-center text-white text-sm mt-3">
          {t("Amount You Want To Contribute")}
        </div>

        <div className="">
          <p className={"my-2"}>
            {t("Choose a currency and amount you want to contribute")}
          </p>
          <div className="flex  flex-col gap-2 ">
            <div className=" w-fit flex  p-2 rounded flex-row  border border-gray-400/70 focus:outline-none focus:ring-2 focus:ring-primary justify-center items-center  ">
              <AppInput2
                name={"amount"}
                register={register}
                placeholder={t("Enter Contribution Amount")}
                label={""}
                validation={validations.amount}
                className="w-full h-full focus:outline-none "
              />
              <select
                onChange={handleCurrencyChange}
                value={currency?.toString()}
                className="border-l focus:outline-none"
              >
                <option value="rwf">RWF</option>
                <option value="usd">USD</option>
              </select>
            </div>
            <div className="flex flex-row justify-start">
              {errors.amount && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.amount.message}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col">
          <div className="p-1 w-full bg-primary items-center flex justify-center text-white text-sm mt-3">
            {t("Select Payment Method")}
          </div>

          {/*<div*/}
          {/*    className=" w-full text-black border-b items-center flex justify-center text-sm mt-1">*/}

          {/*    <div onClick={() => {*/}
          {/*        setHosted(false);*/}
          {/*        setSwitched(false);*/}
          {/*    }} className={`flex items-center justify-center w-1/2 border-r ${!hosted ? 'bg-gray-100' : ''} cursor-pointer hover:bg-gray-50 py-1`}>Direct Payment</div>*/}
          {/*    <div onClick={() => setHosted(true)} className={`flex items-center justify-center w-1/2 ${hosted ? 'bg-gray-100' : ''} cursor-pointer hover:bg-gray-50 py-1`}>Hosted Payment</div>*/}
          {/*</div>*/}
        </div>
        <div className="w-full">
          <div className="w-full flex flex-col items-center">
            <div className="flex flex-row items-center  justify-center py-2 my-3 gap-5 w-full">
              {currency === "rwf" && (
                <img
                  src={mtn}
                  onClick={() => setPayMethod("momo")}
                  alt={"mtl"}
                  className={`h-[3rem] rounded cursor-pointer ${
                    payMethod === "momo" ? "ring-1" : ""
                  } bg-gray-50/60 p-2 border-primary`}
                ></img>
              )}
              <img
                src={visa}
                onClick={() => setPayMethod("card")}
                alt={"card"}
                className={`h-[3rem] rounded cursor-pointer ${
                  payMethod === "card" ? "ring-1" : ""
                } bg-gray-50/60 p-2 border-primary`}
              ></img>
            </div>

            <div className="p-3 border border-slate-200 md:w-full flex min-h-44">
              {payMethod === "momo" ? (
                <div className="w-full items-center flex flex-col">
                  <p className={"text-center text-sm my-2 text-gray-500"}>
                    {t(
                      "To proceed enter MTN mobile number to pay with: eg,0788123456 and make sure you have enough amount to pay."
                    )}
                  </p>
                  <div className="max-w-[250px]">
                    <AppPhoneInput
                      fixed={true}
                      name={"momoPhoneNumber"}
                      register={register}
                      error={errors.momoPhoneNumber?.message}
                      validation={validations.globalRequired}
                      label={t("Phone Number")}
                      placeholder={t("Enter MOMO")}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={
                    " flex w-full min-h-[264px] justify-center items-center "
                  }
                >
                  {/* {!switched && <button
                                        className={'text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2'}>Pay
                                        with Debit / Credit Card</button>}
                                    {switched && <div id={'embed-target'} className={"w-full min-h-[264px]"}></div>} */}
                  <div className=" flex flex-col items-center w-[70%]">
                    {/* Informational text about entering card details */}
                    <p className={"text-center text-sm my-2 text-gray-500"}>
                      {t(
                        " To proceed enter card details to contribute with card payment."
                      )}
                    </p>

                    {/* Grid container for the form fields */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 w-full">
                      {/* Card Number Input */}
                      <AppInput
                        className={"col-span-2"}
                        label={t("Card Number")}
                        placeholder={t("Enter card number")}
                        validation={validations.globalRequired}
                        register={register}
                        error={errors.cardNumber?.message}
                        name={"cardNumber"}
                      />

                      {/* Cardholder Name Input */}
                      <AppInput
                        className={"col-span-2"}
                        label={t("Names")}
                        placeholder={t("Enter names on card")}
                        validation={validations.globalRequired}
                        register={register}
                        error={errors.cardName?.message}
                        name={"cardNames"}
                      />

                      {/* Expiration Date Input */}
                      <div className=" ">
                        <label htmlFor="">Expiration date </label>
                        <div className="grid grid-cols-2 ">
                          <AppInput
                            error={errors.year?.message}
                            validation={validations.year}
                            register={register}
                            name="year"
                            placeholder="ex 2026"
                            type="text"
                          />
                          <AppInput
                            className="ml-2"
                            error={errors.month?.message}
                            validation={validations.month}
                            register={register}
                            name="month"
                            placeholder="ex. 07"
                            type="text"
                          />
                        </div>
                      </div>

                      {/* CVV Input */}
                      <AppInput
                        label={t("CVV")}
                        placeholder={t(
                          "Last 3 digits on the back of your card"
                        )}
                        register={register}
                        error={errors.cvv?.message}
                        validation={validations.globalRequired}
                        name={"cvv"}
                        type={"number"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3 flex flex-col justify-start gap-2">
          <div className="flex flex-row justify-start items-start">
            <div className="flex flex-row  gap-3  ">
              {" "}
              <input
                type="checkbox"
                className="w-5 h-5"
                onChange={(e) => setTermsAccepted(true)}
              />{" "}
              <Terms_and_condition />
            </div>
          </div>
          <button className="bg-primary text-white p-2 rounded px-5">
            {isLoading ? "Loading..." : "Contribute"}
          </button>
        </div>
        {open && (
          <SuccessModal
            text={
              payMethod === "momo"
                ? t(
                    "Thank You!. Please confirm on mobile phone or dial *182*7*1#"
                  )
                : t("Thank You!. Payment initiated.")
            }
            handleClose={() => setOpen(false)}
          />
        )}
      </form>
    </AnimatedContainer>
  );
}
