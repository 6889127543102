import { useState } from "react";
import AnimatedContainer from "../container/animatedContainer";
import { countries } from "../assets/countries";
import Select from "react-select";
import { ValidationRules } from "./types";
interface SelectProps {
  label: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
  register: any;
  name: string;
  className?: string;
  setValue: any;
  validation?: ValidationRules;
  onChange?: any;
}

export default function CountrySelect({
  label,
  error,
  register,
  validation,
  setValue,
  name,
  placeholder,
  required,
  onChange,
}: SelectProps) {
  return (
    <div className="flex flex-col gap-1 relative">
      <label htmlFor="">
        {required ? "*" : ""} {label}
      </label>
      <Select
        {...register(name, validation)}
        onChange={(val: any) => {
          setValue(name, val.value);
          if (onChange) onChange(val);
        }}
        options={countries.map((item) => {
          return {
            value: item.name.toLowerCase(),
            label: item.name,
          };
        })}
        placeholder={placeholder}
      />

      {error && <p className={"text-xs text-red-500"}>{error}</p>}
    </div>
  );
}
