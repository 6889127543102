import image from "../../assets/images/demo2.jpeg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useHandleSuccessMutation} from "../../api/contributions/main";

export default function SuccessPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [send_success, ] = useHandleSuccessMutation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const successId = queryParams.get('orderId');

        sessionStorage.clear();

        send_success({ orderId: successId, status: true  });

        if (!successId) {
            navigate('/');
        }
    }, [location.search]);

    return (
        <div className={'min-h-screen flex flex-row'}>
            <div className="hidden md:block md:w-1/2" style={{
                background: `url('${image}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                backgroundAttachment: 'fixed'
            }}></div>

            <div className="p-5 w-full md:w-1/2">
                <div className="bg-white p-6  md:mx-auto">
                    <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto my-6">
                        <path fill="currentColor"
                              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                        </path>
                    </svg>
                    <div className="text-center">
                        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Done!</h3>
                        <p className="text-gray-600 my-2">Thank you for completing your secure online payment.</p>
                        <p> Have a great day! </p>
                        <div className="py-10 text-center">
                            <Link to="/"
                               className="px-12 bg-primary rounded hover:bg-indigo-500 text-white font-semibold py-3">
                                GO Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}