
const validatePassword = (value : any) => {
    // Regular expressions for password validation
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const lengthRegex = /.{6,}/;


      if (!lowerCaseRegex.test(value)) {
        return 'Password must contain at least one lowercase letter';
    } 
    // else if (!numberRegex.test(value)) {
    //     return 'Password must contain at least one number';
    // } 
    
    else if (!specialCharRegex.test(value)) {
        return 'Password must contain at least one special character';

    } 
    else if (!lengthRegex.test(value)) {
        return 'Password must be at least 6 characters long';
    }

    return true;
};

const validateYear = (value:any) => {
    const strValue = String(value);
    if (!/^\d{4}$/.test(strValue)) {
        return "Year must be a 4-digit number.";
    }
    return true;
};

const validateMonth = (value:any) => {
    const strValue = String(value).padStart(2, '0');
    if (!/^0[1-9]|1[0-2]$/.test(strValue)) {
        return "Month must be between 01 and 12.";
    }
    return true;
};

export const validations = {
    email: {
        required: { value: true, message: 'Email is required' },
    },
    phoneNumber: {
        required: { value: true, message: 'Phone Number is required' },
    },
    names: {
        required: { value: true, message: 'Full Names are required' },
    },
    residence: {
        required: { value: true, message: 'Residence is required' },
    },
    amount: {
        required: { value: true, message: 'Contribution amount is required' }
    },
    password: {
        required: { value: true, message: 'Password is required' },
        validate: validatePassword,
    },
    globalRequired: {
        required: { value: true, message: 'This field is required' }
    },
    pin: {
        required: { value: true, message: 'PIN is required' }
    },
    username: {
        required: { value: true, message: 'Username is required' }
    },
    district: {
        required: { value: true, message: 'District is required' }
    },
    province: {
        required: { value: true, message: 'Province is required' }
    },
    month: {
        required: { value: true, message: 'Month is required' },
        validate: validateMonth,
    },
    year: {
        required: { value: true, message: 'Year is required' },
        validate: validateYear,
    }
};



export function removePlus(phoneNumber: string): string {
    return phoneNumber.replace(/\+/g, '');
}