import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  id: string;
  name: string;
  username: string;
  email: string;
  phoneNumber: string;
  role: 'admin' | 'user'; // Assuming role can only be 'admin' or 'user'
  status: 'active' | 'inactive'; // Assuming status can only be 'active' or 'inactive'
  created_at: string; // Date string in ISO 8601 format
  updated_at: string; // Date string in ISO 8601 format
}

interface UserDetailsState {
  userDetails: User | null;
}

const initialState: UserDetailsState = {
  userDetails: null,
};

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setAppUserDetails(state, action: PayloadAction<User>) {
      state.userDetails = action.payload;
    },
    clearAppUserDetails(state) {
      state.userDetails = null;
    },
  },
});

export const { setAppUserDetails, clearAppUserDetails } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
