import { useRoutes } from "react-router-dom";
import {HomePage} from "./pages/home";
import HomeContainer from "./container/homeContainer";
import NotFoundPage from "./pages/special/NotFound";
import ContributionPage from "./pages/contribution";
import ProfilePage from "./pages/profile";
import AuthPage from "./pages/auth";
import DashContainer from "./container/dashContainer";
import MembersList from "./pages/dashboard/membersList";
import AdminDshboard from "./pages/dash";
import Contributions from "./pages/dashboard/Contributions";
import SuccessPage from "./pages/special/SuccessPage";
import FailPage from "./pages/special/FailedPage";
import TermsAndConditions from "./pages/special/TermsnConditions";
import PrivacyPolicy from "./pages/special/PrivacyPolicy";

export default function AppRouter() {
    return useRoutes([
        {
            path: '/',
            element: <HomeContainer />,
            children: [
                { index: true, element: <HomePage /> },
                { path: '/contribution', element: <ContributionPage />},
                { path: '/profile', element: <ProfilePage /> },
                { path: '/login', element: <AuthPage /> },
                { path: '/success', element: <SuccessPage /> },
                { path: '/fail', element: <FailPage /> },
                { path: '/terms-and-conditions', element: <TermsAndConditions /> },
                { path: '/privacy-policy', element: <PrivacyPolicy /> },
                { path: '*', element: <NotFoundPage /> }
            ]
        },
        {
            path: '/dashboard',
            element: <DashContainer />,
            children: [
                { index: true, element: <AdminDshboard /> },
                { path: 'members', element: <MembersList /> },
                { path: 'contributions', element: <Contributions /> }
            ]
        },
    ])
};