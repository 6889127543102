import fireToast from '../components/toast';
import axios from 'axios';
import store from "../store/store";
import {appLogOut} from "../store/reducers/auth";

export default function handleRefresh(error: any) {
    if(error.error.data.code === 401) {
        console.log('*******************');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('userToken');
        fireToast({message: 'Session expired', type: 'INFO' });
    }
}

export function handleRefreshLocal(error: any) {
    console.log(error.response.status);
    if(error.response.status === 401) {
        console.log('*******************');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('userToken');
        fireToast({message: 'Session expired', type: 'INFO' });
    }
}

// @ts-ignore
export const onQueryStartedError = async (args, { queryFulfilled }) => {
    try {
        const data = await queryFulfilled;
    } catch (error) {
        handleRefresh(error);
    }
};