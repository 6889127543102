import { useState, useEffect } from "react";
import { countries } from "../assets/countries"; 
import "react-phone-number-input/style.css";
import { ValidationRules } from "./types";

interface InputProps {
  label: string;
  placeholder?: string;
  required?: boolean;
  selectedCountry?: string; 
  error?: string;
  register: any;
  name: string;
  setValue?: any;
  className?: string;
  validation?: ValidationRules;
  fixed?: boolean;
}

export const capitalize = (str: string) => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export default function AppPhoneInput({
  label,
  name,
  selectedCountry,
  register,
  error,
  validation,
  placeholder,
  required,
  fixed = false,
  setValue,
}: InputProps) {
  const initialCountry = countries.find(
    (country) => country.name === (selectedCountry ? capitalize(selectedCountry) : 'Rwanda')
  );

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<any>(initialCountry);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const country = countries.find(
      (country) => country.name === (selectedCountry ? capitalize(selectedCountry) : 'Rwanda')
    );
    setSelected(country);
    
  }, [selectedCountry]);
  
  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCountrySelect = (country: any) => {
    setOpen(false);
    setSelected(country);
    if (setValue) {
      setValue("phoneCode", country.phoneCode);
    }
  };

  return (
    <div className="flex flex-col gap-1 relative">
      <label htmlFor="">
        {required ? "*" : ""} {label}
      </label>
      <div
        onClick={() => !fixed && setOpen((prev) => !prev)}
        className="absolute flex flex-row items-center gap-1 cursor-pointer p-1 left-0 mt-[31px]"
      >
        <img
          alt=""
          className={"w-8 rounded overflow-clip h-5"}
          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selected.code}.svg`} // Use HTTPS for images
        />
        <p>{selected.phoneCode}</p>
      </div>
      {open && (
        <div className="absolute flex flex-col h-32 overflow-y-scroll bg-white z-40 border rounded border-gray-300 -bottom-[8.5rem]">
          <input
            type="text"
            className="p-2 border-b border-gray-300 focus:outline-none"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {filteredCountries.map((item, index) => (
            <div
              onClick={() => handleCountrySelect(item)}
              key={index}
              className={
                "p-2 text-xs hover:bg-gray-200 cursor-pointer flex flex-row items-center justify-start gap-2"
              }
            >
              <img
                alt=""
                className={"w-5 h-5"}
                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.code}.svg`} // Use HTTPS for images
              />
              ({item.phoneCode}) {item.name}
            </div>
          ))}
        </div>
      )}
      <input
        {...register(name, validation)}
        className={
          "py-[6.5px] pl-[5rem] rounded px-3 border border-gray-400/70 focus:outline-none focus:ring-2 focus:ring-primary"
        }
        placeholder={placeholder}
        type={"tel"}
      />
      {error && <p className={"text-xs text-red-500"}>{error}</p>}
    </div>
  );
}
