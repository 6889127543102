import React, { useState } from "react";
import AnimatedContainer from "../../../container/animatedContainer";
import { useGetContributions } from "../../../api/contributions/main";
import DataTable from "../../../components/datatable/datatable";
import { useTranslation } from "react-i18next";

export default function MyContributionTab() {
    const userId = localStorage.getItem('userId');
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const {contributions }: any = useGetContributions(pageSize, pageNumber, userId ?? '');

    const {t}= useTranslation();
    const columns: string[] = [
        "transactionDate",
        "member.names",
        "transactionAmount",
        "status",
    ];

    const labels: { [key: string]: string } = {
        transactionDate: t("transactionDate"),
        "member.names": t("names"),
        transactionAmount: t("transactionAmount"),
        status: t("status"),
    };


    return (
        <AnimatedContainer>
            <div className="">
                <div className="shadow overflow-x-scroll">
                    <DataTable
                        data={contributions?? []}
                        columns={columns}
                        labels={labels}
                        totalItems={contributions?.length ?? 0}
                        currentPage={pageNumber}
                        pageSize={pageSize}

                    />
                </div>
            </div>
        </AnimatedContainer>
    )
}