import image from "../../assets/images/demo2.jpeg";
import ProfileTabs from "./tabs";
import {useProtect} from "../../components/isAuth";

export default function ProfilePage() {
    const useless = useProtect();

    return (
        <div className={'min-h-screen flex flex-row'}>
            <div className="hidden md:block md:w-1/2" style={{
                background: `url('${image}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                backgroundAttachment: 'fixed'
            }}></div>

            <div className="p-5 w-full md:w-1/2">
                <h1 className={'text-2xl font-bold mb-3'}>Welcome</h1>

                <ProfileTabs />
            </div>
        </div>
    );
}