import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isBoarded: false,
};

const boardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    onBoard(state) {
      state.isBoarded = true;
    },
    offBoard(state) {
      state.isBoarded = false;
    },
  },
});

export const { onBoard, offBoard } = boardSlice.actions;

export default boardSlice.reducer;
