
export default function PrivacyPolicy() {
    return (
        <div className="px-5 md:px-20 my-20">
            <h1 className={" mb-3 text-xl font-bold"}>Privacy Policy</h1>
            <p>Effective date: 6/17/2024</p>

            <p>Welcome to Intore! This Privacy Policy explains how we collect, use, and protect your personal
                information when you use our contribution collection web app.</p>

            <h2 className={" mb-2 font-bold "}>1. Information We Collect</h2>
            <p>We collect various types of information in connection with the services we provide, including:</p>
            <ul>
                <li><strong>Personal Information:</strong> Name, email address, phone number, and other contact
                    information.
                </li>
                <li><strong>Payment Information:</strong> Credit card details, bank account information, and transaction
                    details.
                </li>
                <li><strong>Usage Data:</strong> Information about how you use our app, including your IP address,
                    browser type, and pages visited.
                </li>
            </ul>

            <h2 className={" mb-2 font-bold "}>2. How We Use Your Information</h2>
            <p>We use the information we collect for the following purposes:</p>
            <ul>
                <li>To provide and maintain our services.</li>
                <li>To process transactions and send confirmations.</li>
                <li>To communicate with you about your account or transactions.</li>
                <li>To improve our services and develop new features.</li>
                <li>To comply with legal obligations and resolve disputes.</li>
            </ul>

            <h2 className={" mb-2 font-bold "}>3. Sharing Your Information</h2>
            <p>We do not share your personal information with third parties except in the following circumstances:</p>
            <ul>
                <li>With your consent.</li>
                <li>To comply with legal requirements or respond to lawful requests.</li>
                <li>With service providers who assist us in operating our app and providing our services.</li>
                <li>In connection with a business transaction, such as a merger or acquisition.</li>
            </ul>

            <h2 className={" mb-2 font-bold "}>4. Data Security</h2>
            <p>We take reasonable measures to protect your personal information from unauthorized access, use, or
                disclosure. However, no internet or email transmission is ever fully secure or error-free.</p>

            <h2 className={" mb-2 font-bold "}>5. Your Rights</h2>
            <p>You have the following rights regarding your personal information:</p>
            <ul>
                <li>The right to access and update your information.</li>
                <li>The right to request the deletion of your information.</li>
                <li>The right to object to the processing of your information.</li>
                <li>The right to request a copy of your information in a structured, commonly used, and machine-readable
                    format.
                </li>
            </ul>

            <h2 className={" mb-2 font-bold "}>6. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
                Privacy Policy on our website.</p>

        </div>
    )
}