import React, { useState } from "react";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';

interface DataColumn {
  [key: string]: string | number | boolean | DataColumn;
}

interface DataTableProps {
  data?: DataColumn[];
  columns?: string[];
  onSearch?: (query: string) => void
  labels?: { [key: string]: string };
  totalItems?: number;
  currentPage?: number;
  pageSize?: number;
  onPageChange?: (page: number) => void;
  actions?: Action[];
  Filter?: any;
}

interface Action {
  label: string;
  onClick: (row: DataColumn) => void;
}

const DataTable: React.FC<DataTableProps> = ({
  data = [],
  columns = [],
  labels = {},
  totalItems = 0,
  currentPage = 1,
  pageSize = 10,
  onPageChange = () => {},
  actions,
  Filter,
  onSearch
}) => {
  const [activeRow, setActiveRow] = useState<number | null>(null);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: any = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const renderCell = (row: DataColumn, column: string) => {
    const nestedFields = column.split(".");
    let value: any = row;
    nestedFields.forEach((field) => {
      if (value && field in value) {
        value = value[field];
      } else {
        value = "-";
      }
    });

    if (typeof value === "string" && (column === "createdAt" || column === "updatedAt" || column === "created_at" || column === "updated_at" || column === "dateOfBirth")) {
      return formatDate(value);
    }

    if (typeof value === "number" && (column === "contributionType") ){
        return changecontributorName(value);
      }
    // Replace null and "" with "-"
    if (value === null || value === "") {
      return "-";
    }
    if (column === "transaction.status") {
        return (
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(value as string)} `}>
            {value}
          </span>
        );
      }
    

    return value;
  };

  
  const lastPage = Math.ceil(totalItems / pageSize);
  const exportToCSV = () => {
    const exportData = data.map((item: DataColumn) => {
      const row: DataColumn = {};
      for (let key of columns) {
        row[labels[key]] = renderCell(item, key);
      }
      return row;
    });

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataExcel = new Blob([excelBuffer], { type: "application/octet-stream" });
    const fileName = "data.xlsx";
    saveAs(dataExcel, fileName);
  };

  const getStatusColor = (status: string) => {
    switch(status) {
      case "approved":
        return "bg-green-100 text-green-800";
      case "rejected":
        return "bg-red-100 text-red-800";
     
    }
  };

  const changecontributorName = (status: number) => {
    switch(status) {
      case 1:
        return "Individual"
      case 2:
        return "Company";
     
    }
  };
  const getStatusColor1
   = (status: string) => {
    switch(status) {
      case "success":
        return "bg-green-100 text-green-800";
      case "fail":
        return "bg-red-100 text-red-800";
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  
  const[searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    if(onSearch)
      onSearch(query); 
  };

  return (
    <div className="overflow-x-auto shadow-md">
      <div className="flex justify-between items-center mb-4">
        <div className="">
          <button
            onClick={() => exportToCSV()}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Export as Excel
          </button>
          <CSVLink
            data={data}
            filename={"data.csv"}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ml-4"
          >
            Export as CSV
          </CSVLink>
        </div>
        <div>
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-500 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
        </div>
        
        <div className="flex-1 flex justify-end sm:hidden">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 ${
              currentPage === 1 ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "bg-white text-gray-700 hover:bg-gray-50"
            }`}
          >
            Previous
          </button>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === lastPage}
            className={`ml-3 relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 ${
              currentPage === lastPage ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "bg-white text-gray-700 hover:bg-gray-50"
            }`}
          >
            Next
          </button>
        </div>
      </div>
      <div>
      {Filter}
      </div>
      <div className="overflow-x-auto">
      <table className={`w-full`}>
        <thead className={`bg-gray-50 border-b border-gray-200`}>
          <tr className="h-10 text-sm text-gray-500 font-[300] items-start text-start">
            {columns.map((column, index) => (
              <th
                key={index}
                className={`px-6 py-3  capitalize text-sm font-bold text-left text-gray-500  whitespace-nowrap`}
              >
                {labels[column]}
              </th>
            ))}
            {actions && actions.length > 0 && <th>Actions</th>}
          </tr>
        </thead>
        <tbody className="capitalize">
          {data?.map((row:any, rowIndex) => (
            <tr
              key={rowIndex}
              className={`text-sm text-gray-500 hover:bg-[#F3F3F3] cursor-pointer ${
                activeRow === rowIndex ? "bg-[#F3F3F3]" : ""
              }`}
              onClick={() => setActiveRow(rowIndex)}
            >
              {columns?.map((column, index) => (
                <td
                  key={index}
                  className={`px-6 py-4 whitespace-nowrap`}
                >
                  {column === "status" ? (
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor1(row[column] as string)} `}>
                      {row[column]}
                    </span>
                  ) : (
                    renderCell(row, column)
                  )}
                </td>
              ))}
              {actions && actions.length > 0 && (
                <td className="flex flex-row justify-center items-center gap-2 ">
                  {actions.map((action, index) => (
                    <button
                      key={index}
                      onClick={() => action.onClick(row)}
                      className={`px-4 py-2 rounded ${
                        action.label === "Reject" ? "bg-red-500 hover:bg-red-400 text-white" : "bg-blue-500 hover:bg-blue-600  text-white"
                      }`}
                    >
                      {action.label}
                    </button>
                  ))}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className="flex justify-between items-center px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
          <p className="text-sm text-gray-500">
            Showing
            <span className="font-medium mx-1">
              {Math.min((currentPage - 1) * pageSize + 1, totalItems)}
            </span>
            to
            <span className="font-medium mx-1">
              {Math.min(currentPage * pageSize, totalItems)}
            </span>
            of
            <span className="font-medium mx-1">{totalItems}</span>results
          </p>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 ${
                currentPage === 1 ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "bg-white text-gray-700 hover:bg-gray-50"
              }`}
            >
              <span className="sr-only">Previous</span>
              {/* Heroicon name: solid/chevron-left */}
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M13.707 8.293a1 1 0 010 1.414L10.414 12l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {[...Array(lastPage)].map((_, index) => (
              <button
                key={index}
                onClick={() => onPageChange(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
                  currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-white text-gray-700 hover:bg-gray-50"
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === lastPage}
              className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 ${
                currentPage === lastPage ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "bg-white text-gray-700 hover:bg-gray-50"
              }`}
            >
              <span className="sr-only">Next</span>
              {/* Heroicon name: solid/chevron-right */}
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M6.293 11.293a1 1 0 010-1.414L9.586 8 6.293 4.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <p className="text-sm text-gray-500">
            Showing
            <span className="font-medium mx-1">
              {Math.min((currentPage - 1) * pageSize + 1, totalItems)}
            </span>
            to
            <span className="font-medium mx-1">
              {Math.min(currentPage * pageSize, totalItems)}
            </span>
            of
            <span className="font-medium mx-1">{totalItems}</span>results
          </p>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 ${
                currentPage === 1 ? "bg-gray-100 text-gray-500 cursor-not-allowed" : "bg-white text-gray-700 hover:bg-gray-50"
              }`}
            >
              <span className="sr-only">Previous</span>
              {/* Heroicon name: solid/chevron-left */}
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M13.707 8.293a1 1 0 010 1.414L10.414 12l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {[...Array(lastPage)].map((_, index) => (
              <button
                key={index}
                onClick={() => onPageChange(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 ${
                  currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-white text-gray-700 hover:bg-gray-50"
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === lastPage}
              className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 ${
                currentPage === lastPage ? "text-gray-100 text-gray-500 cursor-not-allowed" : "bg-white text-gray-700 hover:bg-gray-50"
              }`}
            >
              <span className="sr-only">Next</span>
              {/* Heroicon name: solid/chevron-right */}
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M6.293 11.293a1 1 0 010-1.414L9.586 8 6.293 4.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
