import {useState} from "react";
import NonMemberForm from "./tabs/NonMemberForm";
import MemberForm from "./tabs/MemberForm";
import { t } from "i18next";

export default function ContributionTabs() {
    const [active, setActive] = useState(0);
    const main_tabs = [ (t('Member')), (t('Non Member')) ]

    return (
        <div className={'w-full flex flex-col'}>
            <div className="flex flex-row bg-slate-100 w-full p-1 rounded">
                {main_tabs.map((item, index) =>
                    <div onClick={() => setActive(index)} key={index} className={`p-2 px-5 ${active === index ? 'bg-primary  text-white': 'bg-slate-100 text-black'} cursor-pointer rounded`}>
                        {item}
                    </div>
                )}
            </div>
            <div className="mt-3">
                { active === 0 && <MemberForm /> }
                { active === 1 && <NonMemberForm /> }
            </div>
        </div>
    );
}
