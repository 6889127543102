import React, { useState, useEffect } from "react";
import DataTable from "../../components/datatable/datatable";
import { useGetContributions } from "../../api/auth/main";

const ContributionsListIndividual: React.FC = () => {

  const contributionType=1
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [search, setSearch] = useState(""); // State for search query
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Change this as per your requirement

  const { contributions, error, total }: any = useGetContributions(
    pageSize,
    currentPage,
    phoneNumber,
    contributionType,
    email,
    search 
  );

  const columns: string[] = [
    "amount",
    "currency",
    "tinNumber",
    "companyName",
    "contributorName",
    "phone",
    "phoneNumber",
    "email",
    "residence_country",
    "contributionType",
    "transaction.phoneNumber",
    "transaction.telco",
    "transaction.payment_mode",
    "transaction.amount",
    "transaction.status",
    "createdAt",
  ];

  const labels: { [key: string]: string } = {
    amount: "Amount",
    currency: "Currency",
    tinNumber: "TIN Number",
    companyName: "Company Name",
    contributorName: "Contributor Name",
    phone: "Phone",
    phoneNumber: "Phone Number",
    email: "Email",
    residence_country: "Residence Country",
    contributionType: "Contribution Type",
    createdAt: "Date",
    "transaction.phoneNumber": "Transaction Phone Number",
    "transaction.telco": "Transaction Telco",
    "transaction.payment_mode": "Transaction Payment Mode",
    "transaction.amount": "Transaction Amount",
    "transaction.status": "Transaction Status",
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onSearch = (query: string) => {
    setSearch(query);
  };
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto">
      <h1 className="text-xl font-bold text-center mt-8 mb-4">
       Individual Contributions List
      </h1>

      <DataTable
        data={contributions}
        columns={columns}
        onSearch={onSearch}
        labels={labels}
        totalItems={total}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        Filter={
          <>
            <div className="mb-4">
              <div className="grid grid-cols-2 gap-4">
                
                <div>
                  <label className="block text-sm font-medium text-gray-400">
                    Phone Number:
                  </label>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="Phone Number"
                    className="mt-1 block w-full py-2 px-3 border border-gray-500 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label className=" text-sm font-medium text-gray-400">
                    Email:
                  </label>
                  <input
                    type="text"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Email"
                    className="mt-1 block w-full py-2 px-3 border border-gray-500 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};



export default function ContributionsList() {
    const [active, setActive] = useState(0);
    const main_tabs = ['Individual Contributions', 'Company Contributions'];

    const isLocal = localStorage.getItem('userDetails');
    console.log(isLocal === null );

    return (
        <div className={'w-full flex flex-col'}>
            <div className="flex flex-row bg-slate-200 w-full p-1 rounded">
                {main_tabs.map((item, index) => {
                    return (
                        <div onClick={() => setActive(index)} key={index} className={`p-2 px-5 ${active === index ? 'bg-primary  text-white' : 'bg-slate-200 text-black'} cursor-pointer rounded`}>
                            {item}
                        </div>
                    );
                })}
            </div>
            <div className="mt-3">
                {active === 0 && <ContributionsListIndividual />}
                {active === 1 && <ContributionsListCompany />}
            </div>
        </div>
    );
}

const ContributionsListCompany: React.FC = () => {

  const contributionType=2
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [search, setSearch] = useState(""); // State for search query
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Change this as per your requirement

  const { contributions, error, total }: any = useGetContributions(
    pageSize,
    currentPage,
    phoneNumber,
    contributionType,
    email,
    search 
  );

  const columns: string[] = [
    "amount",
    "currency",
    "tinNumber",
    "companyName",
    "contributorName",
    "phone",
    "phoneNumber",
    "email",
    "residence_country",
    "contributionType",
    "transaction.phoneNumber",
    "transaction.telco",
    "transaction.payment_mode",
    "transaction.amount",
    "transaction.status",
    "createdAt",
  ];

  const labels: { [key: string]: string } = {
    amount: "Amount",
    currency: "Currency",
    tinNumber: "TIN Number",
    companyName: "Company Name",
    contributorName: "Contributor Name",
    phone: "Phone",
    phoneNumber: "Phone Number",
    email: "Email",
    residence_country: "Residence Country",
    contributionType: "Contribution Type",
    createdAt: "Date",
    "transaction.phoneNumber": "Transaction Phone Number",
    "transaction.telco": "Transaction Telco",
    "transaction.payment_mode": "Transaction Payment Mode",
    "transaction.amount": "Transaction Amount",
    "transaction.status": "Transaction Status",
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onSearch = (query: string) => {
    setSearch(query);
  };
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto">
      <h1 className="text-xl font-bold text-center mt-8 mb-4">
       Company Contributions List
      </h1>

      <DataTable
        data={contributions}
        columns={columns}
        onSearch={onSearch}
        labels={labels}
        totalItems={total}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        Filter={
          <>
            <div className="mb-4">
              <div className="grid grid-cols-2 gap-4">
                
                <div>
                  <label className="block text-sm font-medium text-gray-400">
                    Phone Number:
                  </label>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="Phone Number"
                    className="mt-1 block w-full py-2 px-3 border border-gray-500 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label className=" text-sm font-medium text-gray-400">
                    Email:
                  </label>
                  <input
                    type="text"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Email"
                    className="mt-1 block w-full py-2 px-3 border border-gray-500 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};