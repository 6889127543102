import MainHeader from "../../components/mainHeader";
import HomeSlide from "./HomeSlide";

export function HomePage() {
    return (
        <div className={'w-full flex flex-col min-h-screen '}>
            <div className=" w-full">
        
                <HomeSlide />
            </div>
        </div>
    );
}
