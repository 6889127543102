import {ValidationRules} from "./types";

interface InputProps {
    label?: string,
    placeholder?: string,
    required?: boolean,
    type?: string,
    error?: string;
    register: any;
    name: string;
    className?: string;
    validation?: ValidationRules
}

export default function AppInput({ label,className,validation, register,error,name, placeholder, required, type = 'text' }: InputProps) {
    return (
        <div className="flex flex-col gap-1 relative">
            <label htmlFor="">{required ? '*' : ''} {label}</label>
            <input {...register(name, validation)} type={type} className={`py-[6.5px] rounded ${className} px-3 border border-gray-400/70 focus:outline-none focus:ring-2 focus:ring-primary` + className}
                   placeholder={placeholder}/>
            {error && <p className={'text-xs text-red-500'}>{error}</p>}
        </div>
    );
}