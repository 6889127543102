import React from 'react';
import { useGetContributions, useGetMemberList } from '../../api/auth/main';

export default function AdminDashboard() {
    const { members }:any = useGetMemberList(10, 1, '', '');
    const { total }:any = useGetContributions(10, 1, '', 1, '', '',);

    return (
        <div className="flex flex-row gap-2  ">
            <div className="  w-1/3 mx-auto bg-green-500 rounded-lg shadow-lg overflow-hidden">
                <div className="px-4 py-6">
                    <h2 className="text-lg font-semibold text-gray-800 mb-2"> Total Members</h2>
                    <p className="text-sm text-white">Total: {members?.total ?? 0}</p>
                </div>
            </div>

            <div className=" w-1/3  mx-auto bg-blue-500 rounded-lg shadow-lg overflow-hidden ml-6">
                <div className="px-4 py-6">
                    <h2 className="text-lg font-semibold text-gray-800 mb-2"> Total Contributions</h2>
                    <p className="text-sm text-white">Total: {total ?? 0}</p>
                </div>
            </div>
        </div>
    );
}
