// src/components/MainHeader.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo.jpg';
import { NavLink } from "react-router-dom";
import { IoLogoInstagram, IoLogoLinkedin, IoLogoFacebook } from "react-icons/io5";
import { PiXLogo } from "react-icons/pi";
import { FaRegUserCircle } from "react-icons/fa";
import { useAppSelector } from "../store/hooks";

export default function MainHeader({ admin = false } : { admin?: boolean }) {
    const { t, i18n } = useTranslation();
    const { isLoggedIn } = useAppSelector((state) => state.auth);

    const nav_links = [
        { title: t('Home'), location: '' },
        { title: t('About Us'), location: 'https://rpfinkotanyi.rw/index.php?id=3' },
        { title: t('Our Projects'), location: 'https://rpfinkotanyi.rw/index.php?id=293' },
        { title: t('News & Media'), location: 'https://rpfinkotanyi.rw/index.php?id=192' },
        { title: t('Contact Us'), location: 'https://rpfinkotanyi.rw/index.php?id=195' },
    ];

    const changeLanguage = (lng:any) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div>
            <nav className={'flex bg-white z-40 flex-row justify-between items-center w-full'}>
                {!admin && <div className="flex flex-col w-1/5 md:w-2/5 h-auto items-center justify-between">
                    <div className="py-2"></div>
                    <img src={logo} alt={'INTORE'} className={'w-32'} />
                    <div className="flex w-full flex-row justify-end">
                        <div className="h-1 bg-rpfRed w-full"></div>
                    </div>
                </div>}
                <div className={`flex w-4/5  ${admin ? 'md:w-full' : 'md:w-3/5'} flex-col`}>
                    <div className="py-2 bg-primary flex flex-row items-center justify-end">
                        <div className="flex flex-row gap-2 text-white">
                            <p onClick={() => changeLanguage('rw')} className={'hover:underline cursor-pointer'}>{t('Kinyarwanda')}</p>
                            | <p onClick={() => changeLanguage('en')} className={'hover:underline cursor-pointer'}>{t('English')}</p>
                            | <p onClick={() => changeLanguage('fr')} className={'hover:underline cursor-pointer'}>{t('French')}</p>
                        </div>
                        <div className="flex flex-row gap-3 justify-around px-4 text-xl text-white">
                            <IoLogoFacebook />
                            <PiXLogo />
                            <IoLogoLinkedin />
                            <IoLogoInstagram />
                        </div>
                    </div>
                    <div className={'flex flex-row items-center gap-10 my-2'}>
                        <ul className={' flex flex-row justify-end w-full pr-3 gap-2 items-center'}>
                            {nav_links.map((item, index) => (
                                <li key={index} className={' hidden md:block font-semibold mx-1'}>
                                    <NavLink to={`${item.location}`} target="_blank" rel="noopener noreferrer">{item.title}</NavLink>
                                </li>
                            ))}
                            <li className="border-primary border-2 p-1 rounded">
                                <NavLink to={'/contribution'}>{t('Contribution')}</NavLink>
                            </li>
                          
                            {isLoggedIn ? (
                                <li className="text-white bg-primary rounded-full mr-4 cursor-pointer">
                                    <NavLink to={'/profile'}>
                                        <FaRegUserCircle className={'h-10 w-10'} />
                                    </NavLink>
                                </li>
                            ) : (
                                <li className="border-primary border-2 p-1 px-3 rounded">
                                    <NavLink to={'/login'}>{t('Sign In')}</NavLink>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="flex w-full flex-row justify-end">
                        <div className="h-1 bg-primary w-1/2"></div>
                    </div>
                </div>
            </nav>
        </div>
    );
}
