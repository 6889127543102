// src/components/LoginBox.js
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import AppInput from "../../../components/AppInput";
import { validations } from "../../../utils/validations";
import { FormDataLogin } from "../../../components/types";
import AnimatedContainer from "../../../container/animatedContainer";
import CountrySelect from "../../../components/CountrySelect";
import AppPhoneInput from "../../../components/PhoneInput";
import { useLoginMutation, useLocalLogin } from "../../../api/auth/main";
import { useEffect } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { appLogin } from "../../../store/reducers/auth";

export default function LoginBox({
  navigate,
}: {
  navigate: (id: number) => void;
}) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormDataLogin>();

  const [onLogin, { isLoading }] = useLoginMutation();

  const manage_login = async (data: FormDataLogin) => {
    try {
      const publicIpResponse = await fetch("https://api.ipify.org?format=json");
      const { ip: publicIpAddress } = await publicIpResponse.json();

      const localIpAddress = await new Promise((resolve, reject) => {
        const rtcPeerConnection = new RTCPeerConnection({ iceServers: [] });
        rtcPeerConnection.createDataChannel("");
        rtcPeerConnection
          .createOffer()
          .then((offer) => rtcPeerConnection.setLocalDescription(offer))
          .catch((error) => reject(error));
        rtcPeerConnection.onicecandidate = (event) => {
          if (event.candidate) {
            resolve(event.candidate.address);
            rtcPeerConnection.close();
          }
        };
      });

      const computerName = navigator.userAgent;

      const userLoginData = {
        username:
          watch("country") === "rwanda" ? `250${data.username}` : data.username,
        password: data.password,
        userInfo: {
          ipAddress: publicIpAddress,
          localIpAddress: localIpAddress,
          computerName: computerName,
        },
      };

      if (watch("country") === "rwanda") {
        onLogin({ username: userLoginData.username, password: data.pin });
      } else {
        onLogin(userLoginData);
      }
    } catch (error) {
      console.error("Error fetching computer data:", error);
    }
  };

  return (
    <AnimatedContainer>
      <div className={"p-2 flex justify-center items-center"}>
        <form
          onSubmit={handleSubmit(manage_login)}
          className="border w-full md:w-8/12 p-5 flex flex-col gap-3"
        >
          <h1 className={"text-2xl font-bold mb-1"}>{t('Sign In')}</h1>
          <div className="p-1 bg-gray-200/20 rounded grid grid-cols-2 gap-3 flex-row">
            <div
                onClick={() => setValue('country', 'not rwanda')}
                className={`w-full flex justify-center cursor-pointer items-center ${watch('country') !== 'rwanda' ? 'bg-primary text-white' : ''} rounded`}>Email
            </div>
            <div
                onClick={() => setValue('country', 'rwanda')}
                className={`w-full flex justify-center cursor-pointer items-center ${watch('country') === 'rwanda' ? 'bg-primary text-white' : ''} rounded`}>Phone Number
            </div>
          </div>

          {watch("country") === "rwanda" ? (
              <>
                <AppPhoneInput
                    setValue={setValue}
                    fixed={false}
                label={t("Phone Number")}
                placeholder={t("Enter your phone number")}
                register={register}
                name={"username"}
                error={errors.username?.message}
                validation={validations.username}
              />
              <AppInput
                label={t("PIN")}
                type={"password"}
                placeholder={t("Enter your pin")}
                register={register}
                name={"pin"}
                error={errors.pin?.message}
                validation={validations.globalRequired}
              />
            </>
          ) : (
            <>
              <AppInput
                label={t("Email")}
                placeholder={t("Enter your email")}
                register={register}
                name={"username"}
                error={errors.username?.message}
                validation={validations.email}
              />
              <AppInput
                label={t("Password")}
                type={"password"}
                error={errors.password?.message}
                placeholder={t("Enter your password")}
                register={register}
                name={"password"}
                validation={validations.globalRequired}
              />
            </>
          )}

          <button className={"bg-primary text-white p-3 rounded mt-5"}>
            {isLoading ? "Loading..." : t('Sign In')}
          </button>

          <p className={"w-full text-center"}>
            {t('Not a member yet?')}{" "}
            <span
              className={"text-primary cursor-pointer"}
              onClick={() => navigate(1)}
            >
              {t('Join here')}.
            </span>
          </p>
        </form>
      </div>
    </AnimatedContainer>
  );
}
