import { Sidebar } from "../components/sidebar";
import MainHeader from "../components/mainHeader";
import { Outlet, useNavigate } from "react-router-dom";
import {useUserDetailsQuery } from "../api/auth/main";
import { useAppSelector } from "../store/hooks";

export default function DashContainer() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const { data } = useUserDetailsQuery({});

  if ( !isLoggedIn &&  data && data.role !== "admin" ) {
    navigate('/');
    return null;
  }

  console.log(data)
  return (
    <div>
      <Sidebar />
      <div className="sm:ml-64 bg-gray-100 ">
        <MainHeader admin={true} />
        <div className={'p-3 bg-white min-h-[650px] m-10 mx-5'}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
