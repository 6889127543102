import {Link, useLocation} from "react-router-dom";
import logo from "../assets/images/logo.jpg";
import {LuLayoutDashboard} from "react-icons/lu";
import { FaPeopleGroup } from "react-icons/fa6";
import { RiMenu2Fill } from "react-icons/ri";
import {useState} from "react";
import { HiMiniXMark } from "react-icons/hi2";

export const Sidebar = () => {
    const location = useLocation();
    const [open, setOpen] = useState(false);

    const sidebar_items = [
        {
            title: 'Dashboard',
            link: '/dashboard',
            icon: <LuLayoutDashboard />,
            active: location.pathname.includes('dashboard'),
        },
        {
            title: 'Members',
            link: '/dashboard/members',
            icon: <FaPeopleGroup />,
            active: location.pathname.includes('/members'),
        },
        {
            title: 'Contributions',
            link: '/dashboard/contributions',
            icon: <FaPeopleGroup />,
            active: location.pathname.includes('contributions'),
        },
    ]

    return (
        <>

            <button onClick={() => setOpen((prev) => !prev)} data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar"
                    aria-controls="default-sidebar" type="button"
                    className="inline-flex items-center mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 ">
                <span className="sr-only">Open sidebar</span>
                <RiMenu2Fill className={'w-8 h-8 m-2'} />
            </button>

            <aside id="default-sidebar"
                   className={`fixed top-0 left-0 z-40 w-64 h-screen  transition-transform ${open ? 'translate-x-0' : '-translate-x-full sm:translate-x-0'}`}
                   aria-label="Sidebar">
                <div className="h-full overflow-y-auto bg-slate-800 shadow">
                    <div className="h-32 bg-white flex justify-center items-center relative ">
                        <div onClick={() => setOpen(false)} className="absolute sm:hidden text-black right-0 text-2xl top-0 mt-4 mr-4 z-30">
                            <HiMiniXMark />
                        </div>
                        <img src={logo} alt={'FPR'} className={'w-36'} />
                    </div>
                    <ul className="space-y-2 font-medium px-3 py-4">
                        {sidebar_items.map((item, index) => <li>
                            <Link key={index} to={item.link}
                                  className={`${item.active ? 'bg-primary/20' : ''} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-white/20 dark:hover:bg-gray-700 group`}>
                                {item.icon}
                                <span className="ms-3">{item.title}</span>
                            </Link>
                        </li>)}
                    </ul>
                </div>
            </aside>

        </>
    )
}