import React, { ReactNode } from 'react';
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router";
import {AnimatePresence} from "framer-motion";
import {ToastContainer, toast, Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Provider} from "react-redux";
import store from "./store/store";
import {useIsAuth} from "./components/isAuth";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { useCheckQuery } from "./api/auth/main";


type AppProviderProps = {
    children: ReactNode;
};

function AppProvider({ children }: AppProviderProps) {
    const useless = useIsAuth();
    const check = useCheckQuery({});
    return (
        <BrowserRouter>
            {children}
        </BrowserRouter>
    )
}

function App() {
  return (
    <div className="min-h-screen">
        <Provider store={store} >
          <AppProvider>
          <I18nextProvider i18n={i18n}>

              <AnimatePresence>
                  <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      newestOnTop
                      closeOnClick
                      rtl={false}
                      draggable
                      pauseOnHover
                      theme="light"
                      transition={Slide}
                  />
                  <AppRouter />
              </AnimatePresence>
              </I18nextProvider>

          </AppProvider>
        </Provider>
    </div>
  );
}

export default App;
