import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function Terms_and_condition() {
  const [modal, setModal] = React.useState(false);

  return (
    <div className=" ">
      <div className="flex flex-row justify-start items-center">
        <p className="t text-sm cursor-pointer" onClick={(e) => setModal(true)}>
          {" "}
          Confirm to our{" "}
          <span className="text-blue-500 cursor-pointer">
            Terms and conditions
          </span>
        </p>
      </div>
      <Transition appear show={modal} as={Fragment}>
        <Dialog as="div" open={modal} onClose={() => setModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0" />
          </Transition.Child>
          <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
            <div className="flex items-start justify-center min-h-screen px-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                    as="div"
                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-3xl  bg-white  text-black "
                >
                  <TermsComponent />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export const TermsComponent = () => {
  return (
      <>
        <div className="flex bg-[#fbfbfb]  items-center justify-between px-5 py-3">
          <div className="text-lg flex flex-row justify-center w-full">
            Our Terms and conditions
          </div>
        </div>
        <div className="p-5">
          <div className="terms-content">
            <h2>Terms and Conditions</h2>
            <ol className="gap-4 ">
              <li className="terms-item">
                <b>1. Acceptance of Terms:</b> By subscribing to our
                website you agree to the following terms and
                conditions. Please review them thoroughly before
                agreeing to a subscription.
              </li>
              <li className="terms-item">
                <b>2. Subscription Overview</b>
                <ul>
                  <li>
                    • Service/Product Description: Detailed
                    descriptions of the services are available at
                    [link to service/product details]. You are
                    subscribing to these services under the conditions
                    outlined below.
                  </li>
                  <li>
                    • Subscription Term: Subscriptions are
                    automatically renewed each month unless you cancel
                    according to the cancellation policy provided
                    below.
                  </li>
                </ul>
              </li>
              <li className="terms-item">
                <b>3. Billing Initial Setup and Billing:</b>
                <ul>
                  <li>
                    • You will be charged the contribution fee of
                    subscription fee starting from the date of your
                    subscription initiation.
                  </li>
                  <li>
                    • Recurring Billing: Your payment method will be
                    automatically charged at the beginning of each
                    billing cycle on the date corresponding to the
                    commencement of your paying subscription.
                  </li>
                  <li>
                    • Payment Method: By providing your payment
                    information, you authorize us to charge all
                    subscription fees incurred through your account to
                    any such payment instruments.
                  </li>
                  <li>
                    • Change in Fees: Should any change in pricing
                    occur, we will give you advance notice of at least
                    30 days.
                  </li>
                </ul>
              </li>
              <li className="terms-item">
                <b>4. Cancellation and Renewal :</b>
                <ul>
                  <li>
                    • Cancellation Process: You may cancel your
                    contribution at any time by visiting your account
                    settings on our website or by contacting customer
                    support at [contact information].
                  </li>
                  <li>
                    • Renewal: Your contribution will automatically
                    renew under the same conditions unless you cancel
                    it or the company discontinues it.
                  </li>
                  <li>
                    • Effect of Cancellation: Upon cancellation, you
                    will continue to have access until the end of your
                    current paid term.
                  </li>
                </ul>
                <li className="terms-item">
                  <b>5. Refund Policy </b>
                  <ul>
                    <li>
                      • Refunds: We do not offer refunds for any
                      subscriptions except as expressly stated herein,
                      or as required by law.
                    </li>
                    <li>
                      • Free Trials: If your subscription began with a
                      free trial period, you may cancel at any time
                      during the trial period without charge.
                    </li>
                  </ul>
                </li>
                <li className="terms-item">
                  <b>6. Dispute Resolution </b>
                  <ul>
                    <li>
                      • Contact Us First: If you have any issues or
                      discrepancies with your billing or service,
                      please contact our customer service team
                      immediately at [Contact Information] so we can
                      address your concern. Most disputes can be
                      resolved quickly by our customer support team.
                    </li>
                    <li>
                      • Formal Dispute: If we cannot resolve your
                      issue, you retain the right to initiate a
                      chargeback with your card issuer, but we request
                      that you only do this after you have made a
                      reasonable effort to resolve the issue with us
                      directly.
                    </li>
                  </ul>
                </li>
                <li className="terms-item">
                  <b>7. Contact Information </b>
                  <ul>
                    <li>
                      • For any questions, concerns, or service
                      issues, please contact our customer support team
                      at: Email:info@rpfinkotanyi.rw Phone: (+250) 788
                      303 248
                    </li>
                  </ul>
                </li>
                <li className="terms-item">
                  <b>9. Acknowledgment </b>
                  <ul>
                    <li>
                      • By subscribing to our service/product, you
                      acknowledge that you have read these terms and
                      conditions, understand them, and agree to be
                      bound by them.
                    </li>
                  </ul>
                </li>
              </li>
            </ol>
          </div>
        </div>
      </>
  )
}