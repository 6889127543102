import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";
import image from "../../assets/images/demo_image.jpg";
import img2 from "../../assets/images/kagame_fpr_1-2.webp";
import img3 from "../../assets/images/a69df120-d1e8-11ed-aabb-e122177dce6d.jpg";

import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

export default function HomeSlide() {
  const { t } = useTranslation(); // Destructure t function from useTranslation

  const image_arr = [
    image,
    img2,
    "https://live.staticflickr.com/65535/51151419994_ae939d5e9b_b.jpg",
    "https://rpfinkotanyi.rw/fileadmin/user_upload/5.jpg",
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [cookieAccepted, setCookieAccepted] = useState(false);
  const cookieIs = localStorage.getItem("CookieThere");
  const handleAcceptCookie = () => {
    setCookieAccepted(true);
    localStorage.setItem("cookie", "cookieThere");
  };

  const handleRejectCookie = () => {
    localStorage.setItem("cookie", "cookieThere");
    setCookieAccepted(true);
  };

  return (
    <div className="w-full ">
      {!cookieIs && !cookieAccepted && (
        <div className="fixed bottom-0 left-0 w-[40%] bg-gray-800 text-white p-4 text-center z-50">
          <p className="mb-4">{t("This website uses cookies to ensure you get the best experience on our website.")}</p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={handleAcceptCookie}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              {t("Accept")}
            </button>
            <button
              onClick={handleRejectCookie}
              className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
            >
              {t("Reject")}
            </button>
          </div>
        </div>
      )}
      <div className="w-full">
        <Carousel
          interval={5000}
          autoPlay={true}
          onChange={(index) => setSelectedIndex(index)}
          showThumbs={false}
          className="h-"
          emulateTouch={true}
          infiniteLoop={true}
          showStatus={false}
          showIndicators={false}
        >
          {image_arr.map((item, index) => (
            <div
              key={index}
              className={"w-full h-[90vh]"}
              style={{
                background: `url(${item})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          ))}
        </Carousel>
      </div>
      <div className="bg-white text-black">
        <footer className="container mx-auto py-12 px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Quick Links */}
            <div className="footer-section">
              <h3 className="text-lg font-semibold mb-4">{t("Quick Links")}</h3>
              <ul>
                <li>
                  <a href="#">{t("Home")}</a>
                </li>
                <li>
                  <a
                    href="https://rpfinkotanyi.rw/index.php?id=3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("About Us")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://rpfinkotanyi.rw/index.php?id=293"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Our Projects")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://rpfinkotanyi.rw/index.php?id=192"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("News & Media")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://rpfinkotanyi.rw/index.php?id=195"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Contact Us")}
                  </a>
                </li>
              </ul>
            </div>

            {/* Social Media */}
            <div className="footer-section">
              <h3 className="text-lg font-semibold mb-4">{t("Social Media")}</h3>
              <ul>
                <li className="flex items-center mb-4">
                  <FaFacebook className="mr-2" />
                  <a href="https://www.facebook.com/RpfInkotanyi?mibextid=LQQJ4d">
                    Facebook
                  </a>
                </li>
                <li className="flex items-center mb-4">
                  <FaTwitter className="mr-2" />
                  <a href="https://twitter.com/rpfinkotanyi?s=11">{t("Twitter")}</a>
                </li>
                <li className="flex items-center mb-4">
                  <FaInstagram className="mr-2" />
                  <a href="https://www.facebook.com/RpfInkotanyi?mibextid=LQQJ4d">
                    {t("Instagram")}
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact Information */}
            <div className="footer-section">
              <h3 className="text-lg font-semibold mb-4">{t("Contact Us")}</h3>
              <p className="flex items-center mb-2">
                <FaEnvelope className="mr-2" />
                info@rpfinkotanyi.rw
              </p>
              <p className="flex items-center mb-2">
                <FaPhoneAlt className="mr-2" />
                (+250) 789 141 444
              </p>
              <p className="flex items-center mb-2">
                <FaPhoneAlt className="mr-2" />
                (+250) 788 303 248
              </p>
              <p>{t("Rusororo, Gasabo, Kigali - Rwanda")}</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
