import { baseApi } from "../baseApi";
import fireToast from "../../components/toast";
import { ApiResponse, Transaction, UserDetails } from "../../components/types";
import { useEffect, useState } from "react";
import { PRIVATE_API, PRIVATE_API2 } from "../../utils/axios";

export const contributionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    makeContribution: builder.mutation<any, any>({
      query: (payload) => {
        console.log(payload);
        return {
          method: "POST",
          url: "/contributions/member",
          body: payload,
        };
      },
      invalidatesTags: ["CONT"],
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data && data.status === "failed") {
            fireToast({
              message: data.card_response.response.acquirerMessage,
              type: "FAILED",
            });
          } else if (data && data.status === "success") {
            fireToast({ message: "Payment successful", type: "SUCCESS" });
          }
        } catch (e: any) {
          let message = "An unexpected error occurred";
          if (e.error) {
           
              message = Array.isArray(e.error.data.message) ? e.error.data.message.join(", ") : e.error.data.message;
          }
          
    
          fireToast({
            message: message,
            type: "FAILED",
          });
          console.log(message);
        }
      },
    }),
    

    makeContributionNonMember: builder.mutation<any, any>({
      query: (payload) => {
        console.log(payload);
        return {
          method: "POST",
          url: "/contributions",
          body: payload,
        };
      },
      invalidatesTags: ["CONT"],
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data && data.status === "failed") {
            fireToast({
              message: data.card_response.response.acquirerMessage,
              type: "FAILED",
            });
          } else if (data && data.status === "success") {
            fireToast({ message: "Payment successful", type: "SUCCESS" });
          }
        } catch (e: any) {
    
          let message = "An unexpected error occurred";
          if (e.error) {
           
              message = Array.isArray(e.error.data.message) ? e.error.data.message.join(", ") : e.error.data.message;
          }
          
    
          fireToast({
            message: message,
            type: "FAILED",
          });
          console.log(message);
        }
      },
    }),

    contributions: builder.query<ApiResponse<Transaction[]>, any>({
      query: () => ({
        method: "GET",
        url: `/contributions`,
      }),
      providesTags: ["CONT"],
    }),

    requestSession: builder.mutation<any, any>({
      query: (payload) => {
        console.log(payload);
        return {
          method: "POST",
          url: "/transactions/card-session",
          body: payload,
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
        } catch (e: any) {
          const error = e.error.data.error ? e.error.data.error : null;
          const message = e.error.data.message ? e.error.data.message : null;
          fireToast({
            message: error ?? message ?? "An unexpected error occurred",
            type: "FAILED",
          });
          console.log(message);
        }
      },
    }),

    handleSuccess: builder.mutation<any, any>({
      query: (payload) => {
        console.log(payload);
        return {
          method: "POST",
          url: "/contributions/card-callback-url",
          body: payload,
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
        } catch (e: any) {
          console.log(e);
        }
      },
    }),
  }),
});

export const {
  useMakeContributionMutation,
  useHandleSuccessMutation,
  useMakeContributionNonMemberMutation,
  useContributionsQuery,
  useRequestSessionMutation,
} = contributionsApi;

export const useGetContributions = (
  pageSize: number,
  pageNumber: number,
  id: string
) => {
  const [loading, setIsLoading] = useState(false);
  const [contributions, setContributions] = useState<Transaction[] | null>(
    null
  );

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setIsLoading(true);
        const response = await PRIVATE_API.get(
          `/memberContribution/findMemberContributionByMember`,
          {
            params: {
              memberId: id,
              pageSize,
              pageNumber,
            },
          }
        );
        const data: ApiResponse<Transaction[]> = response.data;
        setContributions(data.result);
      } catch (error: any) {
        const errorMessage =
          error.response?.data?.message || "An unexpected error occurred";
        console.log(errorMessage);
        fireToast({ message: errorMessage, type: "FAILED" });
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, [id, pageSize, pageNumber]);

  return {
    loading,
    contributions,
  };
};
export const useGetProvinces = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [provinces, setProvinces] = useState<any[]>([]);

  const fetchProvinces = async () => {
    try {
      setLoading(true);
      const response = await PRIVATE_API2.get("/location/province");
      setProvinces(response.data);
    } catch (error: any) {
      console.error("Error fetching provinces:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProvinces();
  }, []);

  const refetch = () => {
    fetchProvinces();
  };

  return { loading, provinces, refetch };
};

export const useGetDistricts = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [districts, setDistricts] = useState<any[]>([]);

  const fetchDistricts = async (provinceId: string) => {
    try {
      setLoading(true);
      const response = await PRIVATE_API2.get(
        `/location/district/${provinceId}`
      );
      setDistricts(response.data);
    } catch (error: any) {
      console.error("Error fetching districts:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, districts, fetchDistricts };
};

export const useGetSectors = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sectors, setSectors] = useState<any[]>([]);

  const fetchSectors = async (districtId: string) => {
    try {
      setLoading(true);
      const response = await PRIVATE_API2.get(`/location/sector/${districtId}`);
      setSectors(response.data);
    } catch (error: any) {
      console.error("Error fetching sectors:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, sectors, fetchSectors };
};

export const useGetCells = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cells, setCells] = useState<any[]>([]);

  const fetchCells = async (sectorId: string) => {
    try {
      setLoading(true);
      const response = await PRIVATE_API2.get(`/location/cell/${sectorId}`);
      setCells(response.data);
    } catch (error: any) {
      console.error("Error fetching cells:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, cells, fetchCells };
};

export const useGetVillages = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [villages, setVillages] = useState<any[]>([]);

  const fetchVillages = async (cellId: string) => {
    try {
      setLoading(true);
      const response = await PRIVATE_API2.get(`/location/village/${cellId}`);
      setVillages(response.data);
    } catch (error: any) {
      console.error("Error fetching villages:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return { loading, villages, fetchVillages };
};
