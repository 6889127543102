import {ReactNode} from "react";
import { motion } from "framer-motion";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import {FaXmark} from "react-icons/fa6";

export function SuccessModal({ handleClose, text } : { handleClose: () => void, text: string }) {
    return (
        <div className={'fixed bg-black/30 w-full flex justify-center items-start pt-20 h-screen left-0 overscroll-none top-0'}>
            <motion.div

                initial={{ scale: 0.5, y: '10px', opacity: 1 }}
                animate={{ scale: 1, y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}

                className="p-4 pt-0 py-10 px-7 pr-0 mx-3 bg-gray-100 rounded flex flex-col items-center text-center gap-3">
                <div className="py-1 flex flex-row justify-end w-full pr-2">
                    <FaXmark className={'p-1 bg-gray-200 text-2xl rounded cursor-pointer'} onClick={handleClose} />
                </div>
                <div className="p-4 text-[100px] text-green-500 pr-7">
                    <IoMdCheckmarkCircleOutline />
                </div>
                <div className="mx-30 max-w-md pr-7">
                    {text}
                </div>
            </motion.div>
        </div>
    )
}

const Backdrop = ({ children, onClick } : { children: ReactNode, onClick: () => void  }) => {

    return (
        <motion.div
            onClick={onClick}
            className="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {children}
        </motion.div>
    );
};

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
};
