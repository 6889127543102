import { useState } from "react";
import LoginBox from "./Login";
import RegisterBox from "./Register";

export default function AuthContainer() {
    const [active, setActive] = useState(0);

    const navigate = (id: number) => {
        setActive(id);
    };

    return (
        <div
            className="w-full"
        >
            {active === 0 ? <LoginBox navigate={navigate} /> : <RegisterBox navigate={navigate} />}
        </div>
    );
}
