/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import { FormDataRegister } from "../../../components/types";
import AppInput from "../../../components/AppInput";
import { removePlus, validations } from "../../../utils/validations";
import AppPhoneInput from "../../../components/PhoneInput";
import CountrySelect from "../../../components/CountrySelect";
import AppSelect from "../../../components/SelectField";
import AnimatedContainer from "../../../container/animatedContainer";
import { useRegisterMutation } from "../../../api/auth/main";
import { useEffect, useState } from "react";

import {
  useGetProvinces,
  useGetDistricts,
  useGetSectors,
  useGetCells,
  useGetVillages,
} from "../../../api/contributions/main";
import { useTranslation } from "react-i18next";

export default function RegisterBox({
  navigate,
}: {
  navigate: (id: number) => void;
}) {
  const [province, setProvince] = useState("");
  const [provinceName, setProvinceName] = useState("");

  const [district, setDistrict] = useState("");
  const [districtName, setDistrictName] = useState("");

  const [sector, setSector] = useState("");
  const [sectorName, setSectorName] = useState("");

  const [cell, setCell] = useState("");
  const [cellName, setCellName] = useState("");

  const [village, setVillage] = useState("");
  const [villageName, setVillageName] = useState("");

  
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleCountryChange = (val: any) => {
    setSelectedCountry(val.value);
    setValue('residence', val.value); 
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
  } = useForm<FormDataRegister>({
    defaultValues: {
      accepted: false,
      phoneCode: "+250",
    },
  });
  const [register_member, { isLoading, isSuccess, reset }] =
    useRegisterMutation();

  const manage_register = (data: FormDataRegister) => {
    register_member({
      name: data.names,
      gender: data.gender,
      identification_type: data.idType === "nationalId" ? "nid" : "pid",
      nid:
        data.idType === "passportId"
          ? data.passportId ?? ""
          : data.nationalId ?? "",
      dateOfBirth: new Date(data.birthDate),
      email: data.email,
      phoneNumber: `${removePlus(data.phoneCode)}${data.phoneNumber}`,
      residence_country: data.residence,
      locationId:villageName?.toLowerCase() ?? "",
      status: "pending",
      password: data.password,
    });
  };

  const {
    loading: provincesLoading,
    provinces,
    refetch: refetchProvinces,
  } = useGetProvinces();
  const {
    loading: districtsLoading,
    districts,
    fetchDistricts,
  } = useGetDistricts();
  const { loading: sectorsLoading, sectors, fetchSectors } = useGetSectors();
  const { loading: cellsLoading, cells, fetchCells } = useGetCells();
  const {
    loading: villagesLoading,
    villages,
    fetchVillages,
  } = useGetVillages();

  useEffect(() => {
    refetchProvinces();
  }, []);

  useEffect(() => {
    fetchDistricts(province);
  }, [province]);

  useEffect(() => {
    fetchSectors(district);
  }, [district]);

  useEffect(() => {
    fetchCells(sector);
  }, [sector]);

  useEffect(() => {
    fetchVillages(cell);
  }, [cell]);

  const { t } = useTranslation();

  return (
    <AnimatedContainer>
      <div className={"p-2 flex justify-center items-center border"}>
        <div className=" w-full p-5 flex flex-col gap-3">
          <form
            onSubmit={handleSubmit((data) => {
              if (watch("password") !== watch("confirmPassword")) {
                return setError("confirmPassword", {
                  message: t("Passwords do not match"),
                });
              }
              manage_register(data);
            })}
          >
            <h2 className="font-bold ">{t("Join RPF")}</h2>
            <div className="p-1 w-full bg-primary items-center flex justify-center text-white text-sm">
              {t("Basic Info")}
            </div>
            <div className="mt-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                <CountrySelect
                  name={"residence"}
                  setValue={setValue}
                  error={errors.residence?.message}
                  register={register}
                  label={t("Residence")}
                  onChange={handleCountryChange}
                  placeholder={t("Select your residence")}
                  validation={validations.residence}
                  required={true}
                />
                <AppInput
                  name={"names"}
                  register={register}
                  error={errors.names?.message}
                  label={t("Names")}
                  placeholder={t("Enter full names")}
                  validation={validations.names}
                  required={true}
                />
                <AppSelect
                  label={t("Gender")}
                  register={register}
                  name={"gender"}
                  error={errors.gender?.message}
                  setValue={setValue}
                  options={[
                    { label: t("Male"), value: "male" },
                    { label: t("Female"), value: "female" },
                  ]}
                  validation={validations.globalRequired}
                />
                <AppSelect
                  label={t("Identification")}
                  register={register}
                  name={"idType"}
                  error={errors.idType?.message}
                  setValue={setValue}
                  options={[
                    { label: t("National ID"), value: "nationalId" },
                    { label: t("Passport ID"), value: "passportId" },
                  ]}
                  validation={validations.globalRequired}
                />
                {watch("idType") === "nationalId" ? (
                  <AppInput
                    name={"nationalId"}
                    register={register}
                    error={errors.nationalId?.message}
                    label={t("National ID")}
                    placeholder={t("Enter National ID")}
                    type={"number"}
                    validation={validations.globalRequired}
                    required={true}
                  />
                ) : watch("idType") === "passportId" ? (
                  <AppInput
                    name={"passportId"}
                    register={register}
                    error={errors.passportId?.message}
                    label={t("Passport")}
                    placeholder={t("Enter Passport ID")}
                    type={"number"}
                    validation={validations.globalRequired}
                    required={true}
                  />
                ) : null}
                <AppInput
                  name={"birthDate"}
                  register={register}
                  error={errors.birthDate?.message}
                  label={t("Date of Birth")}
                  placeholder={t("Enter your date of birth")}
                  type={"date"}
                  validation={validations.globalRequired}
                  required={true}
                />
              </div>
            </div>
            <div className="p-1 mt-4 w-full bg-primary items-center flex justify-center text-white text-sm">
              {t("Contact Info")}
            </div>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-2">
              <AppInput
                name={"email"}
                register={register}
                error={errors.email?.message}
                label={t("Email")}
                type={"email"}
                placeholder={t("Enter email")}
                validation={validations.email}
                required={true}
              />
              <AppInput
                name={"confirmEmail"}
                register={register}
                error={errors.email?.message}
                label={t("Confirm Email")}
                type={"email"}
                placeholder={t("Enter email")}
                validation={validations.email}
                required={true}
              />
              <AppPhoneInput
                setValue={setValue}
                name={"phoneNumber"}
                selectedCountry={selectedCountry}
                register={register}
                error={errors.phoneNumber?.message}
                label={t("Phone Number")}
                placeholder={t("Enter phone number")}
                validation={validations.phoneNumber}
                required={true}
              />
            </div>
            {watch("residence") === "rwanda" && (
              <>
                <div className="p-1 mt-4 w-full bg-primary items-center flex justify-center text-white text-sm">
                  {t("Location Info/Community")}
                </div>

                <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-2">
                  <div className="mt-4 flex flex-col ">
                    <label htmlFor="provinces">{t("*Province")}</label>
                    <select
                      id="provinces"
                      className={`py-[6.5px] rounded px-3 border ${
                        errors.province
                          ? "border-red-500"
                          : "border-gray-400/70"
                      } focus:outline-none focus:ring-2 focus:ring-primary`}
                      {...register("province", {
                        required: t("Please select a province"),
                      })}
                      onChange={(e: any) => {
                        setProvince(e.target.value);
                        setProvinceName(
                          provinces?.find(
                            (province) => province.id === e.target.value
                          )?.name ?? ""
                        );
                      }}
                    >
                      <option value="">{t("Select Province")}</option>
                      {provinces &&
                        provinces?.map((province) => (
                          <option key={province.id} value={province.id}>
                            {province.name}
                          </option>
                        ))}
                    </select>
                    {errors.province && (
                      <p className="text-red-600 text-xs">
                        {errors.province.message}
                      </p>
                    )}
                  </div>

                  <div className="mt-4 flex flex-col ">
                    <label htmlFor="District">{t("*District")}</label>
                    <select
                      id="District"
                      className={`py-[6.5px] rounded px-3 border ${
                        errors.district
                          ? "border-red-500"
                          : "border-gray-400/70"
                      } focus:outline-none focus:ring-2 focus:ring-primary`}
                      {...register("district", {
                        required: t("Please select a district"),
                      })}
                      onChange={(e: any) => {
                        setDistrict(e.target.value);
                        setDistrictName(
                          districts?.find(
                            (district) => district.id === e.target.value
                          )?.id ?? ""
                        );
                      }}
                    >
                      <option value="">{t("Select District")}</option>
                      {districts &&
                        districts?.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.name}
                          </option>
                        ))}
                    </select>
                    {errors.district && (
                      <p className="text-red-600 text-xs">
                        {errors.district.message}
                      </p>
                    )}
                  </div>

                  <div className="mt-4 flex flex-col ">
                    <label htmlFor="Sector">{t("*Sector")}</label>
                    <select
                      id="Sector"
                      className={`py-[6.5px] rounded px-3 border ${
                        errors.sector ? "border-red-500" : "border-gray-400/70"
                      } focus:outline-none focus:ring-2 focus:ring-primary`}
                      {...register("sector", {
                        required: t("Please select a sector"),
                      })}
                      onChange={(e: any) => {
                        setSector(e.target.value);
                        setSectorName(
                          sectors?.find(
                            (sector) => sector.id === e.target.value
                          )?.name ?? ""
                        );
                      }}
                    >
                      <option value="">{t("Select Sector")}</option>
                      {sectors &&
                        sectors?.map((sector) => (
                          <option key={sector.id} value={sector.id}>
                            {sector.name}
                          </option>
                        ))}
                    </select>
                    {errors.sector && (
                      <p className="text-red-600 text-xs">
                        {errors.sector.message}
                      </p>
                    )}
                  </div>

                  <div className="mt-4 flex flex-col ">
                    <label htmlFor="Cell">{t("*Cell")}</label>
                    <select
                      id="Cell"
                      className={`py-[6.5px] rounded px-3 border ${
                        errors.cell ? "border-red-500" : "border-gray-400/70"
                      } focus:outline-none focus:ring-2 focus:ring-primary`}
                      {...register("cell", {
                        required: t("Please select a cell"),
                      })}
                      onChange={(e: any) => {
                        setCell(e.target.value);
                        setCellName(
                          cells?.find((cell) => cell.id === e.target.value)
                            ?.name ?? ""
                        );
                      }}
                    >
                      <option value="">{t("Select Cell")}</option>
                      {cells &&
                        cells?.map((cell) => (
                          <option key={cell.id} value={cell.id}>
                            {cell.name}
                          </option>
                        ))}
                    </select>
                    {errors.cell && (
                      <p className="text-red-600 text-xs">
                        {errors.cell.message}
                      </p>
                    )}
                  </div>

                  <div className="mt-4 flex flex-col ">
                    <label htmlFor="Village">{t("*Village")}</label>
                    <select
                      id="Village"
                      className={`py-[6.5px] rounded px-3 border ${
                        errors.village ? "border-red-500" : "border-gray-400/70"
                      } focus:outline-none focus:ring-2 focus:ring-primary`}
                      {...register("village", {
                        required: t("Please select a village"),
                      })}
                      onChange={(e: any) => {
                        setVillage(e.target.value);
                        setVillageName(
                          villages?.find(
                            (village) => village.id === e.target.value
                          )?.id ?? ""
                        );
                      }}
                    >
                      <option value="">{t("Select Village")}</option>
                      {villages &&
                        villages?.map((village) => (
                          <option key={village.id} value={village.id}>
                            {village.name}
                          </option>
                        ))}
                    </select>
                    {errors.village && (
                      <p className="text-red-600 text-xs">
                        {errors.village.message}
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="p-1 my-4  w-full bg-primary items-center flex justify-center text-white text-sm">
              {t("Account Security")}
            </div>
            <div className="grid gap-3 grid-cols-1 sm:grid-cols-2">
              <AppInput
                name={"password"}
                register={register}
                error={errors.password?.message}
                label={t("Password")}
                type={"password"}
                placeholder={t("Enter new password")}
                validation={validations.password}
                required={true}
              />

              <AppInput
                name={"confirmPassword"}
                register={register}
                error={errors.confirmPassword?.message}
                label={t("Confirm Password")}
                type={"password"}
                placeholder={t("Confirm Password")}
                validation={validations.password}
                required={true}
              />
            </div>
            <div className="w-full mt-4 flex flex-row items-center gap-2">
              <input
                {...register("accepted", {
                  required: t("Please accept terms and conditions"),
                })}
                type={"checkbox"}
                className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
              />
              <p className={"text-xs"}>
                {t("I have read and consent to RPF INKOTANYI to process my information in accordance with its")}{" "}
                <span className={"text-primary"}>{t("privacy statements")}</span>{" "}
                {t("and")}{" "}
                <span className={"text-primary"}>{t("terms and conditions")}</span>
              </p>
            </div>

            {errors.accepted && (
              <p className={"text-red-600 text-xs"}>
                {errors.accepted.message}
              </p>
            )}
            <div className="mt-3 flex flex-row justify-end items-center">
              <button className="bg-primary text-white p-2 rounded px-5">
                {isLoading ? t("Loading..") : t("Register")}
              </button>
            </div>
          </form>

          <p className={"w-full text-center"}>
            {t("Already a member?")}{" "}
            <span
              className={"text-primary cursor-pointer"}
              onClick={() => navigate(0)}
            >
              {t("Sign In Here")}
            </span>
          </p>
        </div>
      </div>
    </AnimatedContainer>
  );
}
