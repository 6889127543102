import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AnimatedContainer from "../../../container/animatedContainer";
import AppInput from "../../../components/AppInput";
import AppPhoneInput from "../../../components/PhoneInput";
import mtn from "../../../assets/images/mtn.png";
import visa from "../../../assets/images/master.png";
import { validations } from "../../../utils/validations";
import { FormDataMember } from "../../../components/types";
import AuthContainer from "../auth";
import { useAppSelector } from "../../../store/hooks";
import { useGetUserDetails } from "../../../api/auth/main";
import { User } from "../../../store/reducers/userDetails";
import {
  useMakeContributionMutation,
  useMakeContributionNonMemberMutation,
  useRequestSessionMutation,
} from "../../../api/contributions/main";
import fireToast from "../../../components/toast";
import { SuccessModal } from "../../../components/SuccessModal";
import Terms_and_condition from "../../../components/modal/Terms_and_condition";
import { useTranslation } from "react-i18next";
import AppInput2 from "../../../components/AppInput2";

interface ContributionParams {
  isMember: boolean;
  amount: number;
  tinNumber: string;
  contributorName: string;
  phoneNumber: string;
  phone: string;
  contributionType: number;
  paymentMode: string;
  email: string;
  companyName: string;
  channel:string;
  residence_country: string;
  cardPayment?: {
    currency: string;
    card_number: string;
    expiry_month: string;
    expiry_year: string;
    security_code: string;
  };
}
function formatMonth(monthString: string) {
  const month = parseInt(monthString);
  if ( month < 1 || month > 12) {
    throw new Error("Invalid month number. Month must be between 1 and 12.");
  }
  return month < 10 ? `0${month}` : `${month}`;
}

function formatYear(yearString: string) {
  const year = parseInt(yearString);
  if (isNaN(year) || year < 0 || year > 9999) {
    throw new Error("Invalid year number.");
  }
  return String(year).slice(-2); 
}
function formatPhoneNumber(phoneNumber:string) {
  return phoneNumber.startsWith('0') ? phoneNumber.slice(1) : phoneNumber;
}


export default function MemberForm() {
  const [isInst, setIsInst] = useState(false);
  const [open, setOpen] = useState(false);
  const [currency, setCurrency] = useState<"rwf" | "usd" | "eur" | null>("rwf");
  const [payMethod, setPayMethod] = useState<"momo" | "card">("card");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
  } = useForm<FormDataMember>();
  const { t } = useTranslation();
  const userId = localStorage.getItem("userId");
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const { userDetails } = useGetUserDetails(userId ?? "");
  const user = JSON.parse(localStorage.getItem("userDetails") ?? "0") as User;
  const [contribute, { isLoading, isSuccess, data }] =
    useMakeContributionMutation();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [local, localOptions] = useMakeContributionNonMemberMutation();

  console.log(data);
  const manage = (data: FormDataMember) => {
    if (!termsAccepted) {
      return fireToast({
        message: "Please accept the terms and conditions",
        type: "FAILED",
      });
    }
    if (currency !== null) {
      if (payMethod === "momo") {
        
        contribute({
          amount: parseInt(data.amount),
          contributorName: user.name,
          email: user.email,
          phoneNumber: `250${formatPhoneNumber(data?.momoPhoneNumber)}`, 
          paymentMode: payMethod === "momo" ? "momo" : "card",
          contributionType: isInst ? 2 : 1,
          channel:'web',
          companyName: data.companyName,
          tinNumber: data.tin,
        } as ContributionParams);
      } else {
        console.log(user);
        contribute({
          amount: parseInt(data.amount),
          contributorName: user.name,
          email: user.email,
          contributionType: isInst ? 2 : 1,
          companyName: data.companyName,
          tinNumber: data.tin,
          phone: user.phoneNumber,
          channel:'web',
          paymentMode: payMethod,
          cardPayment:
            payMethod === "card"
              ? {
                  currency: currency.toUpperCase(),
                  card_number: data.cardNumber,
                  expiry_month:formatMonth(data?.month),
                  expiry_year: formatYear(data?.year),
                  security_code: data.cvv,
                }
              : null,
        } as ContributionParams);
      }
    } else {
      fireToast({ message: t("Select Currency"), type: "FAILED" });
    }
  };

  const manage_local = (data: FormDataMember) => {
    if (!termsAccepted) {
      return fireToast({
        message: "Please accept the terms and conditions",
        type: "FAILED",
      });
    }
    if (currency !== null) {
      if (payMethod === "momo") {
        local({
          amount: parseInt(data.amount),
          contributionType: isInst ? 2 : 1,
          isMember: true,
          contributorName: userDetails?.names,
          companyName: data.companyName,
          tinNumber: data.tin,
          channel:'web',
          email: userDetails?.email,
          phoneNumber: `250${data.momoPhoneNumber}`,
          paymentMode: payMethod === "momo" ? "momo" : "card",
        } as ContributionParams);
      } else {
        console.log(data?.expDate);
        contribute({
          isMember: true,
          amount: parseInt(data.amount),
          contributorName: userDetails?.names,
          companyName: data.companyName,
          tinNumber: data.tin,
          channel:'web',
          contributionType: isInst ? 2 : 1,
          email: userDetails?.email,
          phone: userDetails?.phoneNumber,
          paymentMode: payMethod,
          cardPayment:
            payMethod === "card"
              ? {
                  currency: currency.toUpperCase(),
                  card_number: data.cardNumber,
                  expiry_month:formatMonth(data?.month),
                  expiry_year: formatYear(data?.year),
                  security_code: data.cvv,
                }
              : null,
        } as ContributionParams);
      }
    } else {
      fireToast({ message: "Select Currency", type: "FAILED" });
    }
  };

  useEffect(() => {
    if (data?.status === "success" || localOptions.isSuccess) {
      setOpen(true);
      reset();
    }
  }, [data, reset]);

  const [requestSession] = useRequestSessionMutation();

  const handleHosted = async (data: FormDataMember) => {
    if (!termsAccepted) {
      return fireToast({
        message: "Please accept the terms and conditions",
        type: "FAILED",
      });
    }
    if (currency === null) {
      return fireToast({ type: "FAILED", message: "Select Currency" });
    }
    if (watch("amount") === "") {
      return setError("amount", { message: "Contribution amount is required" });
    }

    try {
      const response = (await requestSession({
        amount: parseInt(data.amount),
        contributorName: user.name,
        email: user.email,
        phoneNumber: `250${data.momoPhoneNumber}`,
        paymentMode: payMethod,
        currency: currency?.toUpperCase(),
        cancelUrl: `${window.location.origin}/fail`,
        returnUrl: `${window.location.origin}/success`,
      })) as any;
      setSwitched(true);
      // setHosted(true);
      setTimeout(() => {
        //@ts-ignore
        window.Checkout.configure({
          session: {
            id: response.data.session.id,
          },
        });
        //@ts-ignore
        window.Checkout.showEmbeddedPage("#embed-target");
      }, 3000);
    } catch (e) {
      console.log(e);
    }
  };

  const handleHostedLocal = async (data: FormDataMember) => {
    if (!termsAccepted) {
      return fireToast({
        message: "Please accept the terms and conditions",
        type: "FAILED",
      });
    }
    if (currency === null) {
      return fireToast({ type: "FAILED", message: "Select Currency" });
    }
    if (watch("amount") === "") {
      return setError("amount", { message: "Contribution amount is required" });
    }

    try {
      const response = (await requestSession({
        isMember: true,
        amount: parseInt(data.amount),
        contributorName: userDetails?.names,
        contributionType: isInst ? 2 : 1,
        email: userDetails?.email,
        phoneNumber: `250${data.momoPhoneNumber}`,
        paymentMode: payMethod,
        currency: currency?.toUpperCase(),
        cancelUrl: `${window.location.origin}/fail`,
        returnUrl: `${window.location.origin}/success`,
      })) as any;
      setSwitched(true);
      // setHosted(true);
      setTimeout(() => {
        //@ts-ignore
        window.Checkout.configure({
          session: {
            id: response.data.session.id,
          },
        });
        //@ts-ignore
        window.Checkout.showEmbeddedPage("#embed-target");
      }, 3000);
    } catch (e) {
      console.log(e);
    }
  };

  const [switched, setSwitched] = useState(false);
  const handleCurrencyChange = (event: any) => {
    const selectedCurrency = event.target.value;
    setCurrency(selectedCurrency);
    setPayMethod(selectedCurrency === "rwf" ? "momo" : "card");
  };
  return (
    <>
      {isLoggedIn ? (
        <AnimatedContainer>
          <form onSubmit={handleSubmit(userDetails ? manage_local : manage)}>
            <div className="p-1 w-full bg-primary items-center flex justify-center text-white text-sm">
              {t("Basic Info")}
            </div>

            <div className="flex flex-wrap py-2 gap-4 ">
              <label className="relative flex items-center cursor-pointer">
                <input
                  checked={!isInst}
                  onChange={() => setIsInst(false)}
                  className="sr-only peer"
                  name="futuristic-radio"
                  type="radio"
                />
                <div className="w-4 h-4 bg-transparent border-2 border-primary rounded-full peer-checked:bg-primary peer-checked:border-primary transition duration-300 ease-in-out"></div>
                <span className="ml-2">{t("I am an individual")}</span>
              </label>

              <label className="relative flex items-center cursor-pointer">
                <input
                  checked={isInst}
                  onChange={() => setIsInst(true)}
                  className="sr-only peer"
                  name="futuristic-radio"
                  type="radio"
                />
                <div className="w-4 h-4 bg-transparent border-2 border-primary rounded-full peer-checked:bg-primary peer-checked:border-primary transition duration-300 ease-in-out"></div>
                <span className="ml-2">{t("We are an institution")}</span>
              </label>
            </div>

            {userDetails || user ? (
              <div className="">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                  <div className="flex flex-col">
                    <p>{t("Residence")}</p>
                    <div className="p-2 bg-slate-100 rounded mt-1">Rwanda</div>
                  </div>
                  <div className="flex flex-col">
                    <p> {t("Names")}</p>
                    <div className="p-2 bg-slate-100 rounded mt-1">
                      {userDetails?.names ?? user.name}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p>{t("Email")}</p>
                    <div className="p-2 bg-slate-100 rounded mt-1">
                      {userDetails?.email ?? user.email ?? "N/A"}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p>{t("Phone Number")}</p>
                    <div className="p-2 bg-slate-100 rounded mt-1">
                      {userDetails?.phoneNumber ?? user.phoneNumber}
                    </div>
                  </div>
                  {isInst && (
                    <>
                      <AppInput
                        name={"tin"}
                        register={register}
                        label={t("TIN Number")}
                        error={errors.tin?.message}
                        validation={validations.globalRequired}
                        placeholder={t("Enter TIN")}
                      />
                      <AppInput
                        name={"companyName"}
                        register={register}
                        error={errors.companyName?.message}
                        validation={validations.globalRequired}
                        label={t("Company Name")}
                        placeholder={t("Enter Company name")}
                      />
                      <AppInput
                        name={"contributorName"}
                        register={register}
                        error={errors.contributorName?.message}
                        validation={validations.globalRequired}
                        label={t("Contributor Name")}
                        placeholder={t("Enter Contributor name")}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className={"h-44"}></div>
            )}
            <div className="p-1 w-full bg-primary items-center flex justify-center text-white text-sm mt-3">
              {t("Amount You Want To Contribute")}
            </div>

            <div className="">
              <p className={"my-2"}>
                {t("Choose a currency and amount you want to contribute")}
              </p>
              <div className="flex  flex-col gap-2 ">
                <div className="  w-fit p-2 flex rounded  flex-row  border border-gray-400/70 focus:outline-none focus:ring-2 focus:ring-primary justify-center items-center  ">
                  <AppInput2
                    name={"amount"}
                    register={register}
                    placeholder={t("Enter Contribution Amount")}
                    label={""}
                    validation={validations.amount}
                    className="w-full h-full focus:outline-none"
                  />
                  <select
                    onChange={handleCurrencyChange}
                    className="border-l focus:outline-none"
                  >
                    <option value="rwf">RWF</option>
                    <option value="usd">USD</option>
                  </select>
                </div>
                <div className="flex flex-row justify-start">
                  {errors.amount && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.amount.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col">
              <div className="p-1 w-full bg-primary items-center flex justify-center text-white text-sm mt-3">
                {t("Select Payment Method")}
              </div>

              {/*<div*/}
              {/*    className=" w-full text-black border-b items-center flex justify-center text-sm mt-1">*/}

              {/*    <div onClick={() => {*/}
              {/*        setHosted(false);*/}
              {/*        setSwitched(false);*/}
              {/*    }}*/}
              {/*         className={`flex items-center justify-center w-1/2 border-r ${!hosted ? 'bg-gray-100' : ''} cursor-pointer hover:bg-gray-50 py-1`}>Direct*/}
              {/*        Payment*/}
              {/*    </div>*/}
              {/*    <div onClick={() => setHosted(true)}*/}
              {/*         className={`flex items-center justify-center w-1/2 ${hosted ? 'bg-gray-100' : ''} cursor-pointer hover:bg-gray-50 py-1`}>Hosted*/}
              {/*        Payment*/}
              {/*    </div>*/}
              {/*</div>*/}
            </div>

            <div className="w-full">
              <div className="w-full flex flex-col items-center">
                <div className="flex flex-row items-center  justify-center py-2 my-3 gap-5 w-full">
                  {currency === "rwf" && (
                    <img
                      src={mtn}
                      onClick={() => setPayMethod("momo")}
                      alt={"mtl"}
                      className={`h-[3rem] rounded cursor-pointer ${
                        payMethod === "momo" ? "ring-1" : ""
                      } bg-gray-50/60 p-2 border-primary`}
                    ></img>
                  )}
                  <img
                    src={visa}
                    onClick={() => setPayMethod("card")}
                    alt={"card"}
                    className={`h-[3rem] rounded cursor-pointer ${
                      payMethod === "card" ? "ring-1" : ""
                    } bg-gray-50/60 p-2 border-primary`}
                  ></img>
                </div>

                <div className="p-3 border border-slate-200 rounded md:w-full flex min-h-44">
                  {payMethod === "momo" ? (
                    <div className="w-full items-center flex flex-col">
                      <p className={"text-center text-sm my-2 text-gray-500"}>
                        {t(
                          "To proceed enter MTN mobile number to pay with: eg ,0788123456 and make sure you have enough amount to pay."
                        )}
                      </p>
                      <div className="max-w-[250px]">
                        <AppPhoneInput
                          fixed={true}
                          name={"momoPhoneNumber"}
                          register={register}
                          error={errors.momoPhoneNumber?.message}
                          validation={validations.globalRequired}
                          label={"Phone Number"}
                          placeholder={t("Enter MOMO")}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        " flex w-full min-h-[264px] justify-center items-center "
                      }
                    >
                      <div className=" flex flex-col items-center w-[70%]">
                        <p className="text-center text-sm my-2 text-gray-500">
                          {t(
                            "To proceed enter card details to contribute with card payment."
                          )}
                        </p>
                        <div className="grid grid-cols-2 gap-2 w-full ">
                          <AppInput
                            className="col-span-2"
                            label={t("Card Number")}
                            placeholder={t("Enter card number")}
                            validation={validations.globalRequired}
                            error={errors.cardNumber?.message}
                            register={register}
                            name="cardNumber"
                          />
                          <AppInput
                            className="col-span-2"
                            label={t("Names")}
                            error={errors.cardName?.message}
                            placeholder={t("Enter names on card")}
                            validation={validations.globalRequired}
                            register={register}
                            name="cardNames"
                          />
                          <div className=" ">
                            <label htmlFor="">Expiration date </label>
                           <div  className="grid grid-cols-2 ">
                           <AppInput
                            error={errors.year?.message}
                            validation={validations.year}
                           
                            register={register}
                            name="year"
                            placeholder="ex 2026"
                            type="text"
                          />
                          <AppInput
                          className="ml-2"
                            error={errors.month?.message}
                            validation={validations.month}    
                            register={register}
                            name="month"
                            placeholder="ex. 07"
                            type="text"
                          />
                           </div>
                         
                          </div>
                          <AppInput
                            label="CVV"
                            error={errors.cvv?.message}
                            validation={validations.globalRequired}
                            placeholder={t(
                              "Last 3 digits on the back of your card"
                            )}
                            register={register}
                            name="cvv"
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3 flex flex-col justify-start gap-2 ">
              <div className="flex flex-row justify-start items-start ">
                <div className="flex flex-row  gap-3  ">
                  {" "}
                  <input
                    type="checkbox"
                    className="w-5 h-5"
                    onChange={(e) => setTermsAccepted(true)}
                  />{" "}
                  <Terms_and_condition />
                </div>
              </div>
              <button className="bg-primary text-white p-2 rounded px-5">
                {isLoading || localOptions.isLoading
                  ? "Loading..."
                  : "Contribute"}
              </button>
            </div>

            {open && (
              <SuccessModal
                text={
                  payMethod === "momo"
                    ? "Thank You!. Please confirm on mobile phone or dial *182*7*1#"
                    : "Thank You!. Payment initiated."
                }
                handleClose={() => setOpen(false)}
              />
            )}
          </form>
        </AnimatedContainer>
      ) : (
        <AuthContainer />
      )}
    </>
  );
}

function getMonthAndDate(dateString: string) {
  const [month, date] = dateString.split("-");
  const formattedMonth = parseInt(date) < 10 ? `0${parseInt(date)}` : date;
  return { year: month.slice(-2), month: formattedMonth };
}
