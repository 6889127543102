import axios, { AxiosInstance } from 'axios';

export const PRIVATE_API: AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URI}/api`,
});

PRIVATE_API.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('userToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



export const PRIVATE_API2: AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_GTW_URI}/api/v2`,
});

PRIVATE_API2.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('userToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);