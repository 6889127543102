// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          Home: "Home",
          "About Us": "About Us",
          "Our Projects": "Our Projects",
          "News & Media": "News & Media",
          "Contact Us": "Contact Us",
          Contribution: "Contribution",
          LogOut: "LogOut",
          Dashboard: "Dashboard",
          "Sign In": "Sign In",
          "Join here": "Join here",
          Kinyarwanda: "Kinyarwanda",
          English: "English",
          Member: "Member",
          "Non Member": "Non Member",
          transactionDate: "Date",
          names: "Names",
          transactionAmount: "Amount",
          status: "Status",
          French: "French",
          "Country of Residence": "Country of Residence",
          "Phone Number": "Phone Number",
          PIN: "PIN",
          Email: "Email",
          Password: "Password",
          "Enter your phone number": "Enter your phone number",
          "Enter your pin": "Enter your pin",
          "Enter your email": "Enter your email",
          "Enter your password": "Enter your password",
          "Not a member yet?": "Not a member yet?",
          "Join RPF": "Join RPF",
          "Basic Info": "Basic Info",
          Residence: "Residence",
          "Select your residence": "Select your residence",
          Names: "Names",
          "Enter full names": "Enter full names",
          Gender: "Gender",
          Male: "Male",
          Female: "Female",
          Identification: "Identification",
          "National ID": "National ID",
          "Passport ID": "Passport ID",
          "Enter National ID": "Enter National ID",
          Passport: "Passport",
          "Enter Passport ID": "Enter Passport ID",
          "Date of Birth": "Date of Birth",
          "Enter your date of birth": "Enter your date of birth",
          "Contact Info": "Contact Info",
          "Enter email": "Enter email",
          "Confirm Email": "Confirm Email",
          "Enter phone number": "Enter phone number",
          "Location Info/Community": "Location Info/Community",
          "Account Security": "Account Security",
          "Enter new password": "Enter new password",
          "Confirm Password": "Confirm Password",
          Register: "Register",
          "Loading..": "Loading..",
          "Already a member?": "Already a member?",
          "Sign In Here.": "Sign In Here.",
          "Please select a country": "Please select a country",
          "Please enter your phone number": "Please enter your phone number",
          "Please enter your email": "Please enter your email",
          "Please enter your pin": "Please enter your pin",
          "Please enter your password": "Please enter your password",
          "Please enter full names": "Please enter full names",
          "Please select your gender": "Please select your gender",
          "Please select an ID type": "Please select an ID type",
          "Please enter your National ID": "Please enter your National ID",
          "Please enter your Passport ID": "Please enter your Passport ID",
          "Please enter your date of birth": "Please enter your date of birth",
          "Please select a province": "Please select a province",
          "Please select a district": "Please select a district",
          "Please select a sector": "Please select a sector",
          "Please select a cell": "Please select a cell",
          "Please select a village": "Please select a village",
          "Please accept terms and conditions":
            "Please accept terms and conditions",
          "I have read and consent to RPF INKOTANYI to process my information in accordance with its":
            "I have read and consent to RPF INKOTANYI to process my information in accordance with its",
          "privacy statements": "privacy statements",
          "terms and conditions": "terms and conditions",
          "My Special Contributions": "My Special Contributions",
          "Select Province": "Select Province",
          "Select District": "Select District",
          "Company name": "Company name",
          "Enter your company name": "Enter your company name",
          "TIN Number": "TIN Number",
          "Enter your TIN number": "Enter your TIN number",
          "Representative names": "Representative names",
          "Enter representative names": "Enter representative names",
          "Full names": "Full names",
          "Enter your full names": "Enter your full names",
          "Enter your email address": "Enter your email address",
          "Contribution Info": "Contribution Info",
          "Contribution amount": "Contribution amount",
          "Enter amount": "Enter amount",
          "Select Currency": "Select Currency",
          "Pay via mobile money": "Pay via mobile money",
          "Pay with card": "Pay with card",
          "Mobile money phone number": "Mobile money phone number",
          "Card Number": "Card Number",
          "Expiry Date": "Expiry Date",
          CVV: "CVV",
          "Security Code": "Security Code",
          "Accept our": "Accept our",
          "Enter Contribution Amount": "Enter Contribution Amount",
          "Contribution Amount": "Contribution Amount",
          "Submitting...": "Submitting...",
          Submit: "Submit",
          "Choose a currency and amount you want to contribute":"Choose a currency and amount you want to contribute"
        },
      },
      fr: {
        translation: {
          Home: "Accueil",
          "About Us": "À propos de nous",
          LogOut: "Se déconnecter",
          "Our Projects": "Nos projets",
          "News & Media": "Actualités et médias",
          "Contact Us": "Contactez-nous",
          Contribution: "Contribution",
          Dashboard: "Tableau de bord",
          "Sign In": "Se connecter",
          "Join here": "Rejoignez ici",
          Kinyarwanda: "Kinyarwanda",
          English: "Anglais",
          Member: "Membre",
          "Non Member": "Non Membre",
          French: "Français",
          "Country of Residence": "Pays de résidence",
          "Phone Number": "Numéro de téléphone",
          PIN: "Code PIN",
          Email: "Email",
          Password: "Mot de passe",
          "Enter your phone number": "Entrez votre numéro de téléphone",
          "Enter your pin": "Entrez votre code PIN",
          "Enter your email": "Entrez votre email",
          "Enter your password": "Entrez votre mot de passe",
          "Not a member yet?": "Pas encore membre?",
          "Join RPF": "Rejoindre le RPF",
          "Basic Info": "Informations de base",
          Residence: "Résidence",
          "Select your residence": "Sélectionnez votre résidence",
          Names: "Noms",
          "Enter full names": "Entrez vos noms complets",
          Gender: "Genre",
          Male: "Masculin",
          Female: "Féminin",
          Identification: "Identification",
          "National ID": "Carte d'identité nationale",
          "Passport ID": "Numéro de passeport",
          "Enter National ID":
            "Entrez votre numéro de carte d'identité nationale",
          Passport: "Passeport",
          "Enter Passport ID": "Entrez votre numéro de passeport",
          "Date of Birth": "Date de naissance",
          "Enter your date of birth": "Entrez votre date de naissance",
          "Contact Info": "Informations de contact",
          "Enter email": "Entrez votre email",
          "Confirm Email": "Confirmez l'email",
          "Enter phone number": "Entrez votre numéro de téléphone",
          "Location Info/Community": "Infos de localisation/Communauté",
          "Account Security": "Sécurité du compte",
          "Enter new password": "Entrez un nouveau mot de passe",
          "Confirm Password": "Confirmez le mot de passe",
          Register: "S'inscrire",
          "Loading..": "Chargement..",
          "Already a member?": "Déjà membre?",
          "Sign In Here.": "Connectez-vous ici.",
          "Please select a country": "Veuillez sélectionner un pays",
          "Please enter your phone number":
            "Veuillez entrer votre numéro de téléphone",
          "Please enter your email": "Veuillez entrer votre email",
          "Please enter your pin": "Veuillez entrer votre code PIN",
          "Please enter your password": "Veuillez entrer votre mot de passe",
          "Please enter full names": "Veuillez entrer vos noms complets",
          "Please select your gender": "Veuillez sélectionner votre genre",
          "Please select an ID type":
            "Veuillez sélectionner un type d'identification",
          "Please enter your National ID":
            "Veuillez entrer votre numéro de carte d'identité nationale",
          "Please enter your Passport ID":
            "Veuillez entrer votre numéro de passeport",
          "Please enter your date of birth":
            "Veuillez entrer votre date de naissance",
          "Please select a province": "Veuillez sélectionner une province",
          "Please select a district": "Veuillez sélectionner un district",
          "Please select a sector": "Veuillez sélectionner un secteur",
          "Please select a cell": "Veuillez sélectionner une cellule",
          "Please select a village": "Veuillez sélectionner un village",
          "Please accept terms and conditions":
            "Veuillez accepter les termes et conditions",
          "I have read and consent to RPF INKOTANYI to process my information in accordance with its":
            "J'ai lu et j'accepte que le RPF INKOTANYI traite mes informations conformément à ses",
          "privacy statements": "déclarations de confidentialité",
          "terms and conditions": "termes et conditions",
          "I am an individual": "Je suis un individu",
          "We are an institution": "Nous sommes une institution",
          "Tin Number": "Numéro TIN",
          "Enter TIN": "Entrez le numéro TIN",
          "Company Name": "Nom de l'entreprise",
          "Enter Company name": "Entrez le nom de l'entreprise",
          "Contributor Name": "Nom du contributeur",
          "Enter Contributor name": "Entrez le nom du contributeur",
          "Amount You Want To Contribute":
            "Montant que vous souhaitez contribuer",
            "Choose a currency and amount you want to contribute":
            "Choisissez la devise et le montant dans laquelle vous souhaitez contribuer.",

          RWF: "RWF",
          USD: "USD",
          "My Special Contributions": "Mes contributions spéciales",
          "To proceed enter MTN mobile number to pay with: eg, 0788123456 and make sure you have enough amount to pay.":
            "Pour continuer, saisissez le numéro de mobile MTN pour payer avec : par exemple, 0788123456 et assurez-vous d'avoir assez de crédit pour payer.",
          "Enter MOMO": "Entrez MOMO",
          "Contribution amount is required":
            "Le montant de la contribution est requis",
          Contribute: "Contribuer",
          "Select Payment Method": "Sélectionner la méthode de paiement",
          "Pay with Debit / Credit Card": "Payer par carte de débit / crédit",
          "To proceed enter card details to contribute with card payment.":
            "Pour continuer, saisissez les détails de la carte pour contribuer avec un paiement par carte.",
          "Card Number": "Numéro de carte",
          "Enter card number": "Entrez le numéro de carte",
          "Names on card": "Noms sur la carte",
          "Enter names on card": "Entrez les noms sur la carte",
          "Expiration date": "Date d'expiration",
          "Last 3 digits on the back of your card":
            "Trois derniers chiffres au dos de votre carte",
          CVV: "CVV",
          "Thank You!. Please confirm on mobile phone or dial *182*7*1#":
            "Merci!. Veuillez confirmer sur votre téléphone mobile ou composez *182*7*1#",
          "Thank You!. Payment initiated.": "Merci!. Paiement initié.",
          "Please accept the terms and conditions":
            "Veuillez accepter les termes et conditions",
          "Failed to complete contribution.": "Échec de la contribution.",
          "Select Province": "Sélectionner une province",
          "Select District": "Sélectionner un district",
          "Company name": "Nom de l'entreprise",
          "Enter your company name": "Entrez le nom de votre entreprise",
          "TIN Number": "Numéro de TIN",
          "Enter your TIN number": "Entrez votre numéro de TIN",
          "Representative names": "Noms des représentants",
          "Enter representative names": "Entrez les noms des représentants",
          "Full names": "Noms complets",
          "Enter your full names": "Entrez vos noms complets",
          "Enter your email address": "Entrez votre adresse email",
          "Enter Contribution Amount": "Entrez le montant de la contribution",
          "Contribution Info": "Informations sur la contribution",
          "Contribution amount": "Montant de la contribution",
          "Enter amount": "Entrez le montant",
          "Select Currency": "Sélectionnez la devise",
          "Pay via mobile money": "Payer via mobile money",
          "Pay with card": "Payer par carte",
          "Mobile money phone number": "Numéro de téléphone mobile money",
          "Expiry Date": "Date d'expiration",
          "Security Code": "Code de sécurité",
          "Accept our": "Acceptez nos",
          "Submitting...": "En cours de soumission...",
          Submit: "Soumettre",
          transactionDate: "Date",
          names: "Noms",
          transactionAmount: "Montant",
          status: "Statut",
        },
      },
      rw: {
        translation: {
          Home: "Ahabanza",
          LogOut: "Sohokamo",
          "About Us": "Ibyacu",
          "Our Projects": "Imishinga yacu",
          "News & Media": "Amakuru n'Itangazamakuru",
          "Contact Us": "Twandikire",
          Contribution: "Umusanzu",
          Dashboard: "Dashboard",
          "Sign In": "Injira",
          "Join here": "Injira hano",
          Kinyarwanda: "Kinyarwanda",
          English: "Icyongereza",
          Member: "Umunyamuryango",
          "Non Member": "Utaru umunyamuryango",
          French: "Igifaransa",
          "Country of Residence": "Igihugu cy’iwanyu",
          "Phone Number": "Numero ya telefone",
          PIN: "PIN",
          Email: "Emeli",
          Password: "Ijambobanga",
          "My Special Contributions": "Imisanzu yange yihariye",
          "Enter your phone number": "Shyiramo numero yawe ya telefone",
          "Enter your pin": "Shyiramo pin yawe",
          "Enter your email": "Shyiramo email yawe",
          "Enter your password": "Shyiramo ijambobanga yawe",
          "Not a member yet?": "Ntabwo uraba umunyamuryango?",
          "Join RPF": "Injira muri RPF",
          "Basic Info": "Amakuru yibanze",
          Residence: "Igihugu cy'iwanyu",
          "Select your residence": "Hitamo igihugu ",
          Names: "Amazina",
          "Enter full names": "Shyiramo amazina yose",
          Gender: "Igitsina",
          Male: "Gabo",
          Female: "Gore",
          Identification: "Kwinjira mu bihugu",
          "National ID": "Ijambo ry'ubwoko",
          "Passport ID": "Nomero ya pasiporo",
          "Enter National ID": "Shyiramo ijambo ry'ubwoko",
          Passport: "Pasiporo",
          "Enter Passport ID": "Shyiramo nomero ya pasiporo",
          "Date of Birth": "Itariki y'amavuko",
          "Enter your date of birth": "Shyiramo itariki yawe y'amavuko",
          "Contact Info": "Ibitekerezo by'ikoranabuhanga",
          "Enter email": "Shyiramo imeyili yawe",
          "Confirm Email": "Emeza imeyili",
          "Enter phone number": "Shyiramo numero yawe ya telefone",
          "Location Info/Community": "Amazina y'ibijyanye n'umurenge",
          "Account Security": "Igikorwa cy'ibitangaza",
          "Enter new password": "Shyiramo ijambobanga ry'ibanga rishya",
          "Confirm Password": "Emeza ijambobanga ry'ibanga",
          Register: "Injira",
          "Loading..": "Bitera...",
          "Already a member?": "Mukunze kuba munyamuryango?",
          "Sign In Here.": "Kanda hano winjire.",
          "Enter Contribution Amount": "Shyiramo amafaranga yo gutanga",
          "Please select a country": "Muhitemo igihugu",
          "Please enter your phone number": "Mwongere numero yawe ya telefone",
          "Please enter your email": "Mwongere imeyili yawe",
          "Please enter your pin": "Mwongere pin yawe",
          "Please enter your password": "Mwongere ijambobanga yawe",
          "Please enter full names": "Shyiramo amazina yose",
          "Please select your gender": "Mwongere igitsina",
          "Please select an ID type": "Mwongere uko ukwinjira",
          "Please enter your National ID": "Mwongere ijambo ry'ubwoko",
          "Please enter your Passport ID": "Mwongere nomero ya pasiporo",
          "Please enter your date of birth": "Mwongere itariki yawe y'amavuko",
          "Please select a province": "Mwongere intara",
          "Please select a district": "Mwongere akarere",
          "Please select a sector": "Mwongere umurenge",
          "Please select a cell": "Mwongere umudugudu",
          "Please select a village": "Mwongere umudugudu",
          "Please accept terms and conditions": "Mwongere icyo ubikora",
          "I have read and consent to RPF INKOTANYI to process my information in accordance with its":
            "Nkomeje kuvuga ko mbivugaho",
          "privacy statements": "amabwiriza yo gusaba icyemezo",
          "terms and conditions": "urubuga rw'intambara",
          "I am an individual": "Ndi kugiti cyange",
          "We are an institution": "Dufite ishirahamwe",
          "Tin Number": "Nimero ya TIN",
          "Enter TIN": "Shyiramo nimero ya TIN",
          "Company Name": "Izina ry'umuryango",
          "Enter Company name": "Shyiramo izina ry'umuryango",
          "Contributor Name": "Izina ry'utanga",
          "Enter Contributor name": "Shyiramo izina ry'utanga",
          "Amount You Want To Contribute": "Amafaranga yo gutanga",
          "Choose a currency and amount you want to contribute":
            "Hitamo   amafaranga yo gutanga",
          RWF: "RWF",
          USD: "USD",
          "To proceed enter MTN mobile number to pay with: eg, 0788123456 and make sure you have enough amount to pay.":
            "Ku bimenyetso, shiramwo nimeri ya telefoni y'icyamunara MTN wifuze nk'uko: gusa, 0788123456 kandi ushobora kumenya ko udafite amafaranga yo kwishyura.",
          "Enter MOMO": "Shyiramo nimero ya telefone yo gusubira",
          "Contribution amount is required": "Igiciro cy'inguzanyo gitangwa",
          Contribute: "Tanga Umusanzu",
          "Select Payment Method": "Hitamo ibicuruzwa byo kwishyura",
          "Pay with Debit / Credit Card": "Kwishyura n'ikadi ya debit / credit",
          "To proceed enter card details to contribute with card payment.":
            "Ku bimenyetso, shiramwo ibiro by'ikadi wifuze kuranga n'ikarita.",
          "Card Number": "Nimero ya kadi",
          "Enter card number": "Shyiramo nimero ya kadi",
          "Names on card": "Amazina ahari ku kadi",
          "Enter names on card": "Shyiramo amazina ahari ku kadi",
          "Expiration date": "Itariki izarangiriraho",
          "Last 3 digits on the back of your card":
            "imibare 3 inyuma kwikarita",
          CVV: "CVV",
          "Thank You!. Please confirm on mobile phone or dial *182*7*1#":
            "Murakoze cyane!. Ingaruka zibashe gushyiraho ku telefoni yawe cyangwa ukoresha *182*7*1#",
          "Thank You!. Payment initiated.":
            "Murakoze cyane!. Ishyirwaho ryashyizweho.",
          "Please accept the terms and conditions":
            "Mwongere amabwiriza n'inkuru zari mu bitabo",
          "Failed to complete contribution.": "Byanze",
          "Select Province": "Hitamo intara",
          "Select District": "Hitamo akarere",
          "Company name": "Izina ry'ikigo",
          "Enter your company name": "Injiza izina ry'ikigo cyawe",
          "TIN Number": "Nomero ya TIN",
          "Enter your TIN number": "Injiza nomero ya TIN yawe",
          "Representative names": "Amazina y'uhagarariye",
          "Enter representative names": "Injiza amazina y'uhagarariye",
          "Full names": "Amazina yose",
          "Enter your full names": "Injiza amazina yawe yose",
          "Enter your email address": "Injiza aderesi yawe ya imeyili",
          "Contribution Info": "Amakuru ku musanzu",
          "Contribution amount": "Ingano y'umusanzu",
          "Enter amount": "Injiza ingano",
          "Select Currency": "Hitamo ifaranga",
          "Pay via mobile money": "Kwishyura ukoresheje mobile money",
          "Pay with card": "Kwishyura ukoresheje ikarita",
          "Mobile money phone number": "Nomero ya telefone ya mobile money",
          "Expiry Date": "Itariki izarangiriraho",
          "Security Code": "Kode y'umutekano",
          "Accept our": "Akira ibya",
          "Submitting...": "Turimo twohereza...",
          Submit: "Ohereza",
          transactionDate: "Itariki Byabereye",
          names: "Amazina",
          transactionAmount: "Amafaranga",
          status: "Igitego",
        },
      },
    },
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
