import { baseApi } from "../baseApi";
import fireToast from "../../components/toast";
import { ApiResponse, UserDetails } from "../../components/types";
import { useEffect, useState } from "react";
import { PRIVATE_API, PRIVATE_API2 } from "../../utils/axios";
import { useAppDispatch } from "../../store/hooks";
import { appLogin } from "../../store/reducers/auth";
import { User } from "../../store/reducers/userDetails";
import { toast } from "react-toastify";
import { handleRefreshLocal, onQueryStartedError } from "../../utils/refresh";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (payload) => {
        return {
          method: "POST",
          url: "/users/login",
          body: payload,
        };
      },
      invalidatesTags: ["PROFILE"],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data }: { data: { access_token: string; user: User } } =
            await queryFulfilled;
          console.log(data.access_token);
          localStorage.setItem("userToken", data.access_token);
          dispatch(appLogin());
          localStorage.setItem("userDetails", JSON.stringify(data.user));
          fireToast({ message: "Logged In Successfully", type: "SUCCESS" });
        } catch (e: any) {
          if (e) {
            const error = e.error.data.message ? e.error.data.message : null;
            fireToast({
              message: error ?? "An unexpected error occurred",
              type: "FAILED",
            });
          }
        }
      },
    }),

    check: builder.query<User, any>({
      query: () => ({
        method: "GET",
        url: `/users/check`,
      }),
      onQueryStarted: onQueryStartedError,
    }),

    userDetails: builder.query<User, any>({
      query: () => ({
        method: "GET",
        url: `/users/check`,
      }),
      providesTags: ["PROFILE"],
      onQueryStarted: onQueryStartedError,
    }),

    getMembers: builder.mutation<any, any>({
      query: () => {
        return {
          method: "POST",
          url: "/members",
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data }: { data: { access_token: string; user: User } } =
            await queryFulfilled;
        } catch (e: any) {
          if (e) {
            const error = e.error.data.message ? e.error.data.message : null;
            fireToast({
              message: error ?? "An unexpected error occurred",
              type: "FAILED",
            });
          }
        }
      },
    }),

    register: builder.mutation<any, any>({
      query: (payload) => {
        console.log(payload);
        return {
          method: "POST",
          url: "members/register",
          body: payload,
        };
      },
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          fireToast({
            message: "Registration complete, Wait for confirmation.",
            type: "SUCCESS",
          });
        } catch (e: any) {
          let message = "An unexpected error occurred";
          if (e.error) {
           
              message = Array.isArray(e.error.data.message) ? e.error.data.message.join(", ") : e.error.data.message;
          }
          
          fireToast({
            message: message ?? "An unexpected error occurred",
            type: "FAILED",
          });
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useUserDetailsQuery,
  useCheckQuery,
} = authApi;

export const useLocalLogin = () => {
  const [loading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const login = async (payload: { username: string; password: string }) => {
    try {
      setIsLoading(true);
      const response = await PRIVATE_API.post("/login", payload);
      const data: { token: string; memberId: string } = response.data;
      const memberId = data.memberId;
      let token = data.token;
      localStorage.setItem("userToken", token);
      localStorage.setItem("userId", memberId);
      dispatch(appLogin());
      fireToast({ message: "Logged In Successfully", type: "SUCCESS" });
    } catch (e: any) {
      console.log(e);
      fireToast({
        message: e.response.data.message ?? "An unexpected error occurred",
        type: "FAILED",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    loading,
    login,
  };
};

export const useGetUserDetails = (id: string) => {
  const [loading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setIsLoading(true);
        const response = await PRIVATE_API.get(
          `registration/member/findMemberById?memberId=${id}`
        );
        const data: ApiResponse<UserDetails> = response.data;
        setUserDetails(data.result);
      } catch (error: any) {
        handleRefreshLocal(error);
        const errorMessage =
          error.response?.data?.message || "An unexpected error occurred";
        console.log(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    if (id && id !== "") {
      fetchUserDetails();
    }
  }, [id]);

  return {
    loading,
    userDetails,
  };
};

export const useGetUserData = (id: string) => {
  const [loading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setIsLoading(true);
        const response = await PRIVATE_API2.get(`users/${id}`);
        const data: ApiResponse<UserDetails> = response.data;
        setUserDetails(data.result);
      } catch (error: any) {
        handleRefreshLocal(error);
        const errorMessage =
          error.response?.data?.message || "An unexpected error occurred";
        console.log(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    if (id && id !== "") {
      fetchUserDetails();
    }
  }, [id]);

  return {
    loading,
    userDetails,
  };
};

export const useGetMemberList = (
  pageSize: number,
  pageNumber: number,
  search: string,
  status: string
) => {
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<UserDetails[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMembers = async () => {
      setLoading(true);
      try {
        const response: any = await PRIVATE_API2.get("/members", {
          params: {
            pageSize,
            pageNumber,
            search,
            status,
          },
        });
        setMembers(response?.data);
      } catch (error: any) {
        setError(
          error.response?.data?.message || "An unexpected error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [pageSize, pageNumber, search, status]);

  return { loading, members, error };
};

export const approveMembership = async (payload: any) => {
  try {
    const response = await PRIVATE_API2.post("/members/change-status", payload);
    console.log(response);
    toast.success("Member status changed!");
    return response;
  } catch (error: any) {
    console.error("Error approving member:", error);
    toast.error(
      error.response?.data?.message || "An unexpected error occurred"
    );
    throw error;
  }
};

export const useGetContributions = (
  pageSize?: number,
  pageNumber?: number,
  phoneNumber?: string,
  contributionType?: number,
  email?: string,
  search?: string // Include search parameter
) => {
  const [loading, setLoading] = useState(false);
  const [contributions, setContributions] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetchContributions = async () => {
      setLoading(true);
      try {
        const params: any = {};
        if (pageSize) params.pageSize = pageSize;
        if (pageNumber) params.pageNumber = pageNumber;
        if (phoneNumber) params.phoneNumber = phoneNumber;
        if (contributionType) params.contributionType = contributionType;
        if (email) params.email = email;
        if (search) params.search = search;

        const response = await PRIVATE_API2.get("/contributions", {
          params,
        });

        setContributions(response?.data?.list || []);
        setTotal(response?.data?.total || 0);
      } catch (error: any) {
        setError(
          error.response?.data?.message || "An unexpected error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchContributions();
  }, [pageSize, pageNumber, contributionType, phoneNumber, email, search]); // Add search to dependencies

  return { loading, contributions, error, total };
};
