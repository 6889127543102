import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {appLogOut} from "../store/reducers/auth";

const baseQueryWithAuthCheck = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  const baseQueryFn = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_GTW_URI}/api/v2`,
    prepareHeaders: headers => {
      const token = localStorage.getItem('userToken');
      console.log(token);
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    }
  });

  const result = await baseQueryFn(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    api.dispatch(appLogOut());
  }

  return result;
};

export const baseApi = createApi({
  reducerPath: 'api1',
  baseQuery: baseQueryWithAuthCheck,
  endpoints: () => ({}),
  tagTypes: ['PROFILE', 'CONT']
});
