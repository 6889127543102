import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userDetails';
import { baseApi } from '../api/baseApi';
import {authApi} from '../api/auth/main';
import authReducer from './reducers/auth';
import boardReducer from './reducers/onboard';

const middleware = [
  baseApi.middleware,
]

// const persistedReducer = persistReducer(persistConfig, {user: userReducer})

const store = configureStore({
  reducer: {
    [authApi.reducerPath] : authApi.reducer,
    user: userReducer,
    auth: authReducer,
    board: boardReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({}).concat(middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
