import {useGetUserDetails, useUserDetailsQuery} from "../../../api/auth/main";
import {FaRegUserCircle} from "react-icons/fa"
import AnimatedContainer from "../../../container/animatedContainer";
import {useAppDispatch} from "../../../store/hooks";
import {appLogOut} from "../../../store/reducers/auth";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ProfileTab() {
    const userId = localStorage.getItem('userId');
    const { userDetails } = useGetUserDetails(userId ?? '');
    
    const { data } = useUserDetailsQuery({});

    const isLocal = localStorage.getItem('userDetails');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const logOut = () => {
        localStorage.clear();
        dispatch(appLogOut());
        navigate('/');
    }
    const { t } = useTranslation();

    return (
        <AnimatedContainer>
            <div className={'w-full flex flex-col items-center'}>
                <div className="w-full order">
                    <div
                        className="p-1 mb-4 w-full bg-primary items-center flex justify-center text-white text-sm">Profile
                        {t("Basic Info")}
                    </div>
                    <div className="w-full flex flex-col gap-5 items-center sm:flex-row">
                        <div className="w-32 h-32 bg-primary rounded-full text-white">
                            <FaRegUserCircle className={'w-32 h-32'}/>
                        </div>
                        {userDetails || data ? <div className="mt-4 w-full">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                <div className="flex flex-col">
                                    <p>{t("Names")}</p>
                                    <div className="p-2 bg-gray-100 rounded mt-1">{isLocal  ? data?.name : userDetails?.names }</div>
                                </div>
                                <div className="flex flex-col">
                                    <p>{t("Email")}</p>
                                    <div
                                        className="p-2 bg-gray-100 rounded mt-1">{isLocal  ? data?.email : userDetails?.email ?? 'N/A'}</div>
                                </div>
                                <div className="flex flex-col">
                                    <p>{t("Phone Number")}</p>
                                    <div
                                        className="p-2 bg-gray-100 rounded mt-1">{isLocal  ? data?.phoneNumber : userDetails?.phoneNumber}</div>
                                </div>
                                {userDetails && <div className="flex flex-col">
                                    <p>{t("Residence")}</p>
                                    <div
                                        className="p-2 bg-gray-100 rounded mt-1">{userDetails.location.parent?.description}</div>
                                </div>}
                            </div>
                        </div> : <div className={'h-44'}></div>}
                    </div>

                    <div
                        className="p-1 mb-4 w-full bg-primary items-center flex justify-center text-white text-sm mt-4">Account
                    </div>
                    <div onClick={() => logOut()} className="flex flex-row items-center py-2 justify-end w-full">
                        <button className={'bg-red-500 text-white p-2 rounded'}>{t("LogOut")}</button>
                    </div>
                </div>
            </div>
        </AnimatedContainer>
    )
}